import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Payment-successful-page.webp';

const TermPage = (props) => {

    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Thank you';
    }, []);
    return (
        <>
         <Helmet>
                <meta name="description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Thank you" />
                <meta property="og:description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Thankyou</div>
                            <h2 className="page-title_heading">Thankyou </h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Terms & Coditions</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                    <h2>Your Payment Was Successfully Processed</h2>
                        <p>
                        Thank you for completing your payment for the Exclusive Ticket to the Forttuna Global Excellence Awards show. We are excited to have you join us in celebrating excellence and innovation.                        </p>
                        <br/>
                        <br/>
                        <h2>Stay Connected:</h2>
                        <p>– In the meantime, feel free to explore our website to learn more about our award categories, past winners, and upcoming events.</p>
                        <p>– Connect with us on social media to stay updated on the latest news and announcements from the Forttuna Global Excellence Awards.</p>
                        <h2>Contact Us:</h2>
                        <p>– If you have any questions or need further assistance, please don’t hesitate to reach out to our support team at <a href="mailto:contact@forttuna.co">contact@forttuna.co</a>. We are here to help you every step of the way.</p>
                        <p>Thank you once again for choosing the Forttuna Global Excellence Awards. We look forward to celebrating your achievements!</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default TermPage;
