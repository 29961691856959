import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import blogImg1 from "../../images/blog/Upcoming-Health-Events-for-Industry-Leaders-in-2024.webp";
import blogImg2 from "../../images/blog/Financial-Services-Awards.webp";
import blogImg3 from "../../images/blog/How-a-healthcare-award-can-take-your-career.webp";
import blogImg4 from "../../images/blog/positive-change-in-healthcare.webp";
import blogImg5 from "../../images/blog/Global-entertainment.webp";
import blogImg6 from "../../images/blog/Beyond-the-Trophy-How-Medical-Awards-Can-Propel-Your-Career.webp";
import blogImg7 from "../../images/blog/Unveiling-the-Top-10-Awards-In-India-That-Can-Boost-Your-Business.webp";
import blogImg8 from "../../images/blog/Aviation-Awards-2024.webp";
import blogImg9 from "../../images/blog/2024-Healthcare-Marketing-Awards-Building-Your-Legacy.webp";
import blogImg10 from "../../images/blog/Leadership-in-Healthcare-Awards-Beyond-the-Badge-at-Forttuna.webp";
import blogImg11 from "../../images/blog/Beyond-the-Trophy-How-Medical-Awards-Can-Propel-Your-Career.webp";
import blogImg12 from "../../images/blog/4-Health-Service-Awards-That-Can-Impact-Career-Growth.webp";
import blogImg13 from "../../images/blog/Real-Estate-Awards.webp";
import blogImg14 from "../../images/blog/healthcare-leadership.webp";
import blogImg15 from "../../images/blog/Upcoming-Awards.webp";
import blogImg16 from "../../images/blog/Healthcare-network.webp";
import blogImg17 from "../../images/blog/Wellness-Awards-2024.webp";
import blogImg18 from "../../images/blog/Unmiss-healthcare-Event.webp";
import blogImg19 from "../../images/blog/Upcoming-Awards.webp";
import blogImg20 from "../../images/blog/Healthcare-benefit-winning.webp";
import blogImg21 from "../../images/blog/Unmiss-healthcare-Event.webp";
import blogImg22 from "../../images/blog/Global-health-initiative.webp";
import blogImg23 from "../../images/blog/Upcoming-Awards.webp";
import blogImg24 from "../../images/blog/Unveiling-the-Top-10-Awards-In-India-That-Can-Boost-Your-Business.webp";
import blogImg25 from "../../images/blog/How-Winning-The-UK-Tech-Awards-Fuels-Your-Business-Growth.webp";
import blogImg26 from "../../images/blog/Award-Ceremony-Outfits-2024-Nail-It-&-Stand-Out.webp";
import blogImg27 from "../../images/blog/Australian-Small-Business-Champion-Awards-2024.webp";
import blogImg28 from "../../images/blog/Restaurant-award.webp";
import blogImg29 from "../../images/blog/Your-Guide-to-Winning-a-Prestigious-UK-Business-Award.webp";
import blogImg30 from "../../images/blog/Growing-Business-Award.webp";
import blogImg31 from "../../images/blog/Hospitality-Award-Rates-&-Categories.webp";
import blogImg32 from "../../images/blog/How-the-Restaurant-Industry-Award-Catalyzes-Growth.webp";
import blogImg33 from "../../images/blog/How-Small-Business-Awards-2024-Lift-Spirits-and-Drive-Ambition.webp";
import blogImg34 from "../../images/blog/How-Corporate-Recognition-Awards-Connect-&-Elevate-Leaders.webp";
import blogImg35 from "../../images/blog/Upcoming-Award-Shows-2024-Where-Merit-Wins-No-Pay-to-Play.webp";
import blogImg36 from "../../images/blog/Upcoming-Awards.webp";
import blogImg37 from "../../images/blog/Business-Excellence-Award-Win-Recognition-&-Spark-Growth.webp";
import blogImg38 from "../../images/blog/The-Networking-Power-of-Award-Shows-&-How-to-Leverage-It.webp";
import blogImg39 from "../../images/blog/How-Award-Shows-2024-Can-Propel-Your-Business-or-Cause.webp";

const blogs = [
    { image: blogImg1, tag: "Event", link: "/upcoming-health-events/", date: "July 25, 2024", author: "Forttuna Awards", title: "Upcoming Health Events for Industry Leaders in 2024" },
    { image: blogImg2, tag: "Award", link: "/financial-services-awards/", date: "July 20, 2024", author: "Forttuna Awards", title: "Financial Services Awards: Celebrating Innovation and Impact" },
    { image: blogImg3, tag: "Career", link: "/healthcare-award/", date: "July 18, 2024", author: "Forttuna Awards", title: "How a Healthcare Award Can Take Your Career to Great Heights" },
    { image: blogImg4, tag: "Health", link: "/doctor-award/", date: "July 15, 2024", author: "Forttuna Awards", title: "How a Doctor Award Drives Positive Change in Healthcare" },
    { image: blogImg5, tag: "Entertainment", link: "/global-entertainment-awards/", date: "July 10, 2024", author: "Forttuna Awards", title: "The Impact of Global Entertainment Awards on Your Career" },
    { image: blogImg6, tag: "Career", link: "/women-leaders-in-healthcare/", date: "July 5, 2024", author: "Forttuna Awards", title: "Beyond the Stethoscope: Honoring Women Leaders in Healthcare" },
    { image: blogImg7, tag: "Business", link: "/modern-healthcare-awards/", date: "June 30, 2024", author: "Forttuna Awards", title: "Unveiling the Modern Healthcare Awards: Why Winning It Matters" },
    { image: blogImg8, tag: "Aviation", link: "/aviation-awards-2024/", date: "June 25, 2024", author: "Forttuna Awards", title: "The Aviation Awards 2024: Soar High & Achieve Recognition" },
    { image: blogImg9, tag: "Marketing", link: "/health-marketing-awards/", date: "June 20, 2024", author: "Forttuna Awards", title: "2024 Healthcare Marketing Awards-Building Your Legacy" },
    { image: blogImg10, tag: "Leadership", link: "/leadership-in-healthcare/", date: "June 15, 2024", author: "Forttuna Awards", title: "Leadership in Healthcare Awards: Beyond the Badge at Forttuna" },
    { image: blogImg11, tag: "Career", link: "/medical-awards/", date: "June 10, 2024", author: "Forttuna Awards", title: "Beyond the Trophy: How Medical Awards Can Propel Your Career" },
    { image: blogImg12, tag: "Health", link: "/service-awards/", date: "June 5, 2024", author: "Forttuna Awards", title: "4 Health Service Awards That Can Impact Career Growth" },
    { image: blogImg13, tag: "Real Estate", link: "/real-estate/", date: "June 1, 2024", author: "Forttuna Awards", title: "4 Best Real Estate Awards to Boost Your Brand’s Impact" },
    { image: blogImg14, tag: "Leadership", link: "/healthcare-leadership/", date: "May 28, 2024", author: "Forttuna Awards", title: "The Best Healthcare Leadership Awards To Build Network" },
    { image: blogImg15, tag: "Event", link: "/2024-award-shows/", date: "May 25, 2024", author: "Forttuna Awards", title: "Why the 2024 Award Shows Matter for Industry Leaders" },
    { image: blogImg16, tag: "Health", link: "/healthcare-networking-events/", date: "May 20, 2024", author: "Forttuna Awards", title: "Mastering Healthcare Networking Events: Elevate Your Impact" },
    { image: blogImg17, tag: "Wellness", link: "/wellness-awards/", date: "July 2024", author: "Forttuna Awards", title: "Wellness Awards 2024: Networking & Growth Opportunities" },
    { image: blogImg18, tag: "Event", link: "/healthcare-asia-awards-2024/", date: "July 2024", author: "Forttuna Awards", title: "Unveiling the Benefits of Winning Healthcare Asia Awards 2024" },
    { image: blogImg19, tag: "Industry", link: "/what-is-an-industry-award/", date: "July 2024", author: "Forttuna Awards", title: "What is an Industry Award? Elevate Your Career and Brand" },
    { image: blogImg20, tag: "Health", link: "/health-awards/", date: "July 2024", author: "Forttuna Awards", title: "Winning the Prestigious Health Awards: Invest in Your Reputation" },
    { image: blogImg21, tag: "Event", link: "/healthcare-events/", date: "July 2024", author: "Forttuna Awards", title: "4 Unmissable Healthcare Events for Innovation & Recognition" },
    { image: blogImg22, tag: "Health", link: "/global-health-initiatives/", date: "July 2024", author: "Forttuna Awards", title: "The Impact of Global Health Initiatives and Awards Recognition" },
    { image: blogImg23, tag: "Award", link: "/global-awards/", date: "July 2024", author: "Forttuna Awards", title: "How Global Awards Foster Industry Connections & Win You Clients!" },
    { image: blogImg24, tag: "Business", link: "/top-10-awards-in-india/", date: "July 2024", author: "Forttuna Awards", title: "Unveiling the Top 10 Awards In India That Can Boost Your Business" },
    { image: blogImg25, tag: "Tech", link: "/uk-tech-awards/", date: "July 2024", author: "Forttuna Awards", title: "How Winning The UK Tech Awards Fuels Your Business Growth" },
    { image: blogImg26, tag: "Fashion", link: "/award-ceremony-outfits/", date: "July 2024", author: "Forttuna Awards", title: "Award Ceremony Outfits 2024: Nail It & Stand Out" },
    { image: blogImg27, tag: "Business", link: "/australian-small-business-champion-awards/", date: "July 2024", author: "Forttuna Awards", title: "Australian Small Business Champion Awards 2024 – Win Big" },
    { image: blogImg28, tag: "Food", link: "/restaurant-award/", date: "July 2024", author: "Forttuna Awards", title: "Unveiling the Secrets of Winning a Restaurant Award (2024) – Win Big" },
    { image: blogImg29, tag: "Business", link: "/uk-business-award/", date: "July 2024", author: "Forttuna Awards", title: "Your Guide to Winning a Prestigious UK Business Award (2024)" },
    { image: blogImg30, tag: "Business", link: "/growing-business-award/", date: "July 2024", author: "Forttuna Awards", title: "Growing Business Award: The Unexpected Benefits of Winning It" },
    { image: blogImg31, tag: "Hospitality", link: "/hospitality-award-rates/", date: "July 2024", author: "Forttuna Awards", title: "A Guide to Hospitality Award Rates & Categories (2024 Edition)" },
    { image: blogImg32, tag: "Food", link: "/restaurant-industry-award/", date: "July 2024", author: "Forttuna Awards", title: "How the Restaurant Industry Award Catalyzes Growth" },
    { image: blogImg33, tag: "Small Business", link: "/small-business-awards-2024/", date: "July 2024", author: "Forttuna Awards", title: "How Small Business Awards 2024 Lift Spirits and Drive Ambition" },
    { image: blogImg34, tag: "Corporate", link: "/b2b-marketing-awards/", date: "July 2024", author: "Forttuna Awards", title: "Leverage B2B Marketing Awards for Growth: More Than Recognition" },
    { image: blogImg35, tag: "Corporate", link: "/corporate-recognition-awards/", date: "July 2024", author: "Forttuna Awards", title: "How Corporate Recognition Awards Connect & Elevate Leaders" },
    { image: blogImg36, tag: "Event", link: "/upcoming-award-shows-2024/", date: "July 2024", author: "Forttuna Awards", title: "Upcoming Award Shows 2024 Where Merit Wins: No Pay-to-Play" },
    { image: blogImg37, tag: "Business", link: "/business-excellence-awards/", date: "July 2024", author: "Forttuna Awards", title: "Business Excellence Awards: Win Recognition & Spark Growth" },
    { image: blogImg38, tag: "Event", link: "/award-shows-forttuna/", date: "July 2024", author: "Forttuna Awards", title: "The Networking Power of Award Shows & How to Leverage It" },
    { image: blogImg39, tag: "Event", link: "/award-shows-2024-forttuna/", date: "July 2024", author: "Forttuna Awards", title: "How Award Shows 2024 Can Propel Your Business or Cause" },
];

const BlogList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 9;
    const totalPages = Math.ceil(blogs.length / blogsPerPage);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            ClickHandler();
        }
    };

    const displayedBlogs = blogs.slice((currentPage - 1) * blogsPerPage, currentPage * blogsPerPage);

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="content-side col-lg-12 col-md-12 col-sm-12">
                        <div className="our-blog">
                            <div className="row clearfix">
                                {displayedBlogs.map((blog, index) => (
                                    <div key={index} className="news-block_one col-lg-4 col-md-6 col-sm-12">
                                        <div className="news-block_one-inner">
                                            <div className="news-block_one-tag">{blog.tag}</div>
                                            <div className="news-block_one-image">
                                                <Link onClick={ClickHandler} to={blog.link}>
                                                    {/* <img src={blog.imagea} alt={blog.title} /> */}
                                                    <img src={blog.image} alt={blog.title} />
                                                    <img src={blog.image} alt={blog.title} />
                                                </Link>
                                            </div>
                                            <div className="news-block_one-content">
                                                <div className="news-block_one-date">{blog.date}</div>
                                                <ul className="news-block_one-meta">
                                                    <li style={{color: '#000'}}><span className="icon fa fa-calendar"></span>by {blog.author}</li>
                                                </ul>
                                                <h5 className="news-block_one-title"><Link onClick={ClickHandler} to={blog.link}>{blog.title}</Link></h5>
                                                <Link onClick={ClickHandler} to={blog.link} className="news-block_one-more">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <ul className="styled-pagination text-center">
                                <li className={`prev ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <Link onClick={() => handlePageChange(currentPage - 1)} to="#">
                                        <span className="fa fa-angle-left"></span>
                                    </Link>
                                </li>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                                        <Link onClick={() => handlePageChange(index + 1)} to="#">{index + 1}</Link>
                                    </li>
                                ))}
                                <li className={`next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <Link onClick={() => handlePageChange(currentPage + 1)} to="#">
                                        <span className="fa fa-angle-right"></span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <BlogSidebar blLeft={props.blLeft} /> */}
                </div>
            </div>
        </div>
    );
}

export default BlogList;
