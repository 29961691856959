import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Aim.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre';
import FunFact from '../../components/FunFact';
// import Features from '../../components/Features';
import WhyAttendS3 from '../../components/WhyAttendS3/WhyAttendS3';
import OurAim from '../../components/OurAim1/OurAim';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
// import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const AboutPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Our Aim';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Global Excellence Awards aims to attract a diverse and accomplished demographic that spans various industries and sectors." />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Our Aim" />
            <meta property="og:description" content="Forttuna Global Excellence Awards aims to attract a diverse and accomplished demographic that spans various industries and sectors." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Our Aim</div>
                            <h2 className="page-title_heading">Our Aim</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Our Aim</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <OurAim />
            <WhyAttendS3 />
            {/* <VideoArea /> */}
            {/* <PartnerSection /> */}
            {/* <VenueSection /> */}
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default AboutPage;
