import React from 'react'
import bg from '../../images/background/pattern-6.png'
import bg2 from '../../images/background/pattern-10.png'
import { Link } from 'react-router-dom'

const WhyAttendS2 = (props) => {

    const Service = [
        {
            title: 'Individual Achievers',
            des: 'Visionary leaders, innovators, and professionals who have made significant contributions to their industries. Trailblazers and pioneers who have demonstrated excellence in various domains.',
            icon: 'flaticon-connection'
        },
        {
            title: 'Corporate Entities',
            des: 'Companies and organisations that have excelled in areas such as technology, business, sustainability, and corporate social responsibility. Industry leaders setting benchmarks for excellence and innovation.',
            icon: 'flaticon-book'
        },
        {
            title: 'Entrepreneurs and Startups',
            des: 'Innovators and disruptors who have launched successful ventures or introduced groundbreaking solutions. Emerging businesses that showcase excellence and promise in their early stages.',
            icon: 'flaticon-idea'
        },
        {
            title: 'Cultural and Creative Icons',
            des: 'Individuals and entities contributing to the arts, culture, and creative industries. Those making a significant impact in fields such as music, film, literature, and the visual arts.',
            icon: 'flaticon-book'
        },
        {
            title: 'Philanthropists and Social Impact Leaders',
            des: 'Individuals and organisations dedicated to making a positive societal impact through philanthropy and social responsibility. Those actively involved in community development and social initiatives.',
            icon: 'flaticon-book'
        },
        {
            title: 'Global Influencers',
            des: 'Thought leaders, influencers, and public figures with a global reach and impact. Individuals shaping public discourse and contributing to positive change on a global scale.',
            icon: 'flaticon-planet-earth'
        },
        {
            title: 'Technology and Innovation Leaders',
            des: 'Innovators, scientists, and technologists driving advancements in technology and innovation. Companies at the forefront of technological breakthroughs and digital transformation.',
            icon: 'flaticon-book'
        },
        {
            title: 'Sustainability Champions',
            des: 'Entities committed to sustainable practices and environmental stewardship. Organisations promoting eco-friendly solutions and contributing to a sustainable future.',
            icon: 'flaticon-book'
        },
        {
            title: 'Networking and Collaboration Seekers',
            des: 'Forttuna Global Excellence Awards attracts individuals and entities seeking networking opportunities, collaboration, and exposure within an exclusive community of like-minded professionals and industry influencers.',
            icon: 'flaticon-book'
        },
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="services-one">
            {/* <div className="services-one_pattern-one" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="services-one_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title centered title-anim">
                    {/* <div className="sec-title_title">Join The Event</div> */}
                    <h2 className="sec-title_heading">Why Attend Event?</h2>
                </div>
                <div className="row clearfix">
                    {Service.map((service, sitem) => (
                        <div className="service-block_one col-lg-4 col-md-6 col-sm-12" key={sitem}>
                            <div className="service-block_one-inner" style={{height: '465px'}}>
                                <div className={`service-block_one-icon fi ${service.icon}`}></div>
                                <h4 className="service-block_one-heading"><Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop">{service.title}</Link></h4>
                                <div className="service-block_one-text">{service.des}</div>
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="service-block_one-arrow fi flaticon-next-2"></Link>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>

    )
}

export default WhyAttendS2;