import React from 'react';
import { Link } from 'react-router-dom'
import blogtp from '../../images/resource/news-17.jpg'
import blogtp2 from '../../images/resource/author-5.jpg'
import blog1 from '../../images/resource/author-1.jpg'
import blog2 from '../../images/resource/author-2.jpg'
import blog3 from '../../images/resource/author-3.jpg'
// import blogs from '../../api/blogs';
import blogSingleImg1 from "../../images/resource/news-15.jpg"
import { useParams } from 'react-router-dom'
// import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {

    // const { slug } = useParams()

    // const BlogDetails = blogs.find(item => item.slug === slug)

    // const submitHandler = (e) => {
    //     e.preventDefault()
    // }

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="content-side col-lg-12 col-md-12 col-sm-12">
                        <div className="blog-detail">
                            <div className="blog-detail_inner">
                                <div className="blog-detail_image">
                                    <img src={blogSingleImg1} alt="" />
                                </div>
                                <div className="blog-detail_content">
                                    <div className="blog-detail_date">27 July 2023</div>
                                    <ul className="blog-detail_meta">
                                        <li style={{ listStyle: 'disc' }} ><span className="icon fa fa-regular fa-calendar"></span>by Forttuna Awards</li>
                                    </ul>
                                    <h2>2024 Healthcare Marketing Awards-Building Your Legacy</h2>
                                    <p>Every year, the Healthcare Marketing Awards illuminate the most innovative, impactful, and inspiring campaigns in the health sector. As we edge closer to the 2024 awards, it's crucial to recognize the profound legacy that transcends mere recognition. For high-achieving individuals&mdash;be you a C executive, a sought-after researcher, or a head-turning industry innovator&mdash;these awards represent more than a trophy; they signal a seasoned commitment to excellence and a beacon guiding the future of healthcare.</p>
                                    <h1 style={{fontSize :'40px'}}>Legacy: More Than Healthcare Marketing Awards</h1>
                                    <p>Carving out a sustained impact in the healthcare marketing awards industry is no ordinary feat. Top-tier professionals like yourselves understand that legacy is not built overnight nor through a single project. Dedicating each facet of your campaign to broader conversations about patient care, healthcare accessibility, and <a href="https://www.sagelyhealth.com/blog-posts/what-are-innovative-treatments-and-why-should-i-consider-them#:~:text=An%20innovative%20treatment%20is%20a,improve%20outcomes%20for%20cancer%20patients.)">innovative treatments</a> can differentiate you from your peers.</p>
                                    <h3>Bridging Innovation and Recognition</h3>
                                    <p>At the heart of every healthcare marketing campaign is the potential to drive transformative changes in healthcare delivery and patient engagement. Winning a <a href="https://forttuna.co/leadership-in-healthcare/">leadership in healthcare award</a> at the illustrious 2024 Healthcare Marketing Awards could pivotally elevate your project, transforming it from a successful marketing strategy to a significant milestone in healthcare innovation.</p>
                                    <h3>Stepping Stones to Industry Leadership</h3>
                                    <p>Achieving recognition at the Healthcare Marketing Awards goes beyond individual accolades. It&rsquo;s a testament to your vision and leadership, distinguishing you among industry titans. Let's explore how these awards can serve as stepping stones to greater industry influence:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Validation of Your Strategies:</strong> Awards act as a benchmark for excellence.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Accelerated Peer Recognition:</strong> Sharing the stage with other top professionals can expedite networking opportunities.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Enhanced Visibility:</strong> Healthcare Marketing Awards raise your profile, increasing your influence across the healthcare ecosystem.</li>
                                    </ul>
                                    <h2>Strategies for Legacy-Building Campaigns</h2>
                                    <p>When crafting your submission for the Healthcare Marketing Awards, consider these strategies to not only catch the judges' eyes but to also ensure your work leaves a lasting legacy.</p>
                                    <h3>Impact Through Innovation</h3>
                                    <p>Ensure your campaign is steeped in originality and innovation. Whether it's through employing cutting-edge technology or pioneering unheard-of approaches to common problems, innovation is what sets legacy-crafting campaigns apart.</p>
                                    <h3>Community and Patient Involvement</h3>
                                    <p>Engage with the communities and patients you're aiming to help. Campaigns that resonate with real-world user input stand out as more grounded and impactful, demonstrating a commitment to real-world improvements in healthcare.</p>
                                    <h3>Sustainability and Long-Term Vision</h3>
                                    <p>Focus on long-term impact. Campaigns that address immediate concerns while laying groundwork for sustainable health practices showcase foresight and thought leadership, key elements in legacy building.</p>
                                    <h3>Amplifying Your Influence Post-Awards</h3>
                                    <p>Winning at the Healthcare Marketing Awards is only a part of your journey to building a lasting legacy. Here&rsquo;s how you can amplify your influence following a win:</p>
                                    <h3>Leveraging Media and Public Relations</h3>
                                    <p>Capitalize on your achievement by amplifying your victory across various media platforms. Press releases, interviews, and feature articles can help magnify the reach of your success, attracting new clients, investors, and partners.</p>
                                    <h3>Continuing the Conversation</h3>
                                    <p>Use the recognition as a stepping stone to spearhead important industry dialogues. Whether through speaking engagements or by participating in panel discussions, staying active in the community keeps your campaign&mdash;and its message&mdash;in the spotlight.</p>
                                    <h3>Mentoring Emerging Leaders</h3>
                                    <p>Sharing your insights and experiences with up-and-coming professionals not only helps them grow&mdash;it also cements your status as a thought leader and visionary in the healthcare sector.</p>
                                    <h3>Calling All Healthcare Marketing Mavens: The Forttuna Awards Await!</h3>
                                    <p><strong>Think your healthcare marketing campaign deserves a standing ovation?</strong></p>
                                    <p>The Forttuna Global Excellence Awards, happening December 6th &amp; 7th in Dubai, UAE, is seeking nominations for 2024. This acclaimed event isn't just about accolades &ndash; it's about shining a spotlight on groundbreaking healthcare marketing.</p>
                                    <p><strong>Forttuna Recognizes Marketing Muscle</strong></p>
                                    <p>Forget "pay to play" awards. Forttuna is all about merit. Renowned experts meticulously judge nominees based on clear criteria, guaranteeing recognition for campaigns that truly move the needle.</p>
                                    <p><strong>Why Nominate Your Healthcare Marketing Campaign?</strong></p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Become a Healthcare Marketing Hero:</strong> Get the recognition you deserve for your innovative and impactful marketing strategies.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Transparency Reigns Supreme:</strong> A fair and unbiased selection process ensures genuine marketing brilliance wins the day.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Go Global:</strong> Elevate your agency's profile and become a force to be reckoned with on the international healthcare stage.</li>
                                    </ul>
                                    <p><strong>The Forttuna Advantage for Healthcare Marketers:</strong></p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Global Recognition:</strong> Be celebrated for your marketing mastery, inspiring others and propelling your success to new heights.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Elite Networking:</strong> Mingle and collaborate with healthcare marketing's top minds and industry leaders at exclusive events.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>A Night to Remember:</strong> Immerse yourself in a night of celebration with inspiring speakers and esteemed company.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Amplify Your Voice:</strong> Leverage extensive media coverage to showcase your agency's capabilities and attract new clients.</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><strong>Credibility and Trust:</strong> Solidify your reputation for excellence with a respected international jury selection process.</li>
                                    </ul>
                                    <p><strong>Find Your Perfect Category:</strong></p>
                                    <p>Forttuna has a dedicated category designed to recognize outstanding marketing achievements in the healthcare industry.</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>AYUSH</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Spa, Wellness &amp; Yoga (Company &amp; Brands)</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Hospital &amp; Clinic</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Individual Categories Doctors, Nurses, Researchers, etc.</li>
                                </ul>
                                <h3>Conclusion: Your Blueprint for Lasting Impact</h3>
                                <p>As you prepare your submission for the 2024 Healthcare Marketing Awards, remember that this is more than a competition. It's an opportunity to hallmark your commitment to excellence and to engrave your influence on the healthcare landscape. Your work has the power not only to transform your professional trajectory but also to shape the future of healthcare. Embrace this chance to not only shine but to inspire, lead, and legacy-make.</p>
                                <p>The stage is set, and the world is watching. How will you make your mark with the 2024 Healthcare Marketing Awards?</p>
                                <p><a href="https://participate.forttuna.co/">Participate today</a> and celebrate your success on the global stage.</p>
                                <p><a href="https://participate.forttuna.co/">Join us in India</a> for the awards!</p>
                                <p>Uncover the inspiring story of Dr. Monika Kaushal- <a href="https://www.youtube.com/watch?v=Ni97Ux06XmU&amp;t=9s">Click here.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <BlogSidebar /> */}
                </div>
            </div>
        </div>
    )

}

export default BlogSingle;
