import timg1 from '../images/resource/Amit-SHeth.png'
import timg2 from '../images/resource/Dr.-Adil-Sajwani.png'
import timg3 from '../images/resource/Dr.-Ebrahim-al-al.png'
import timg4 from '../images/resource/Moran-Cerf.png'
import timg5 from '../images/resource/Ritesh-Mehtaa.png'
import timg6 from '../images/resource/Sultan.png'
import timg7 from '../images/resource/yaqoob-al-ali.png'
import timg8 from '../images/resource/Subramanyam-Yadavalli-1.webp'
import timg9 from '../images/resource/Ravi-Tharoor.png'
import timg10 from '../images/resource/Majid.png'
import timg11 from '../images/resource/Prof-Sandra.png'
import timg12 from '../images/resource/Ahmed-Al-Hosani.png'
import timg13 from '../images/resource/Armin-Shahriari.png'

import tsimg1 from '../images/resource/Amit-SHeth.png'
import tsimg2 from '../images/resource/Dr.-Adil-Sajwani.png'
import tsimg3 from '../images/resource/Dr.-Ebrahim-al-al.png'
import tsimg4 from '../images/resource/Moran-Cerf.png'
import tsimg5 from '../images/resource/Ritesh-Mehtaa.png'
import tsimg6 from '../images/resource/Sultan.png'
import tsimg7 from '../images/resource/yaqoob-al-ali.png'
import tsimg8 from '../images/resource/Subramanyam-Yadavalli-1.webp'
import tsimg9 from '../images/resource/Ravi-Tharoor.png'
import tsimg10 from '../images/resource/Majid.png'
import tsimg11 from '../images/resource/Prof-Sandra.png'
import tsimg12 from '../images/resource/Ahmed-Al-Hosani.png'
import tsimg13 from '../images/resource/Armin-Shahriari.png'


const Teams = [
   {
      Id: '1',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Sultan Alameri',
      slug: 'Sultan Alameri',
      title: 'Deputy Vice Chancellor, Emirates College for Advanced Education, UAE',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Dr. Adil Saeed Sajwani',
      slug: 'Dr. Adil Saeed Sajwani',
      title: 'Public Speaker, Healthcare Influencer, Deputy Medical Director, Mediclinic, UAE',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Ebrahim Al Alkeem',
      slug: 'Dr. Ebrahim Al Alkeem',
      title: 'Executive Office of AML & CTF, Government of UAE',
   },
   {
      Id: '4',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Eng. Ahmed Al Hosani',
      slug: 'Eng. Ahmed Al Hosani',
      title: "UAE's First Classical Singer, Emirati Musicians' Association",
   },
   {
      Id: '5',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Moran Cerf',
      slug: 'Dr. Moran Cerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '6',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Riitesh Mehtaa',
      slug: 'Riitesh Mehtaa',
      title: 'Serial Entrepreneur & Business Advisor, The Forttuna Group, UAE',
   },
   {
      Id: '7',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Amit Sheth',
      slug: 'Amit Sheth',
      title: "Award-winning Serial Entrepreneur & Philanthropist, India",
   },
   {
      Id: '8',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Yaqoob Al Ali',
      slug: 'Yaqoob Al Ali',
      title: 'Executive Director & Private Advisor, Office of His Highness Sheikh Juma Bin Maktoum Al Maktoum, UAE',
   },
   {
      Id: '9',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Subramanyam Yadavalli',
      slug: 'Subramanyam Yadavalli',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },
   {
      Id: '10',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Ravi Tharoor',
      slug: 'Ravi Tharoor',
      title: 'Former CEO, Khaleej Times, UAE',
   },
   {
      Id: '11',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Prof. Dr. Sandra Matz',
      slug: 'Sandra-Matz',
      title: 'Professor of Business, Columbia Business School, USA',
   },
   {
      Id: '12',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Armin Shahriari',
      slug: 'Armin Shahriari',
      title: "Deputy Secretary General and CEO of HTDC Accelerator",
   },

]

export default Teams;