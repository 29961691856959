
import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD40/BlogD40';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'How Award Shows 2024 Can Propel Your Business or Cause';
    }, []);
    return (
        <>
        <Helmet>
                <meta name="description" content="Engaging in award shows 2024 puts you in the same room as other high achievers and potential collaborators. Participate Today!" />
                <meta property="og:title" content="How Award Shows 2024 Can Propel Your Business or Cause" />
                <meta property="og:description" content="Engaging in award shows 2024 puts you in the same room as other high achievers and potential collaborators. Participate Today!" />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="award shows 2024" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='How Award Shows 2024 Can Propel Your Business or Cause' pagesub={''} pageTitle2={''}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default BlogDetails;
