


import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/clients/Forbes-India-Logo.png'
import pimg2 from '../../images/clients/DNA.png'
import pimg3 from '../../images/clients/khaleej-times.png'
import pimg4 from '../../images/clients/PTI-News-Logo.png'
import pimg5 from '../../images/clients/Gulf-News.png'
import pimg6 from '../../images/clients/the-print.png'
import pimg7 from '../../images/clients/The-Week-News-Logo.png'
// import pimg8 from '../../images/clients/ABP-News.png'
// import pimg9 from '../../images/clients/Hindustan-Times.png'
import pimg10 from '../../images/clients/ani-News-logo2.png'
import pimg11 from '../../images/clients/Bloomberg-News.png'
import pimg12 from '../../images/clients/Moody-Analytics.png'
import pimg13 from '../../images/clients/crush-base.png'
import pimg14 from '../../images/clients/Menafn.png'
// import pimg15 from '../../images/clients/Latestly.png'


const partners = [
    {
        pImg: pimg2,
        link: 'https://www.dnaindia.com/health/report-forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements-and-innovation-3077752',
        alt: 'DNA India',
    },
    {
        pImg: pimg3,
        link: 'https://www.khaleejtimes.com/kt-network/forttuna-global-excellence-awards-2023-a-resounding-success-celebrating-healthcare-pioneers',
        alt: 'Khaleej Times',
    },
    {
        pImg: pimg4,
        link: 'https://www.ptinews.com/press-release/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements/1287598',
        alt: 'PTI News',
    },
    {
        pImg: pimg5,
        link: 'https://gulfnews.com/business/corporate-news/forttuna-global-excellence-awards-2023-honoring-healthcare-heroes-1.1709713317163',
        alt: 'Gulf News',
    },
    {
        pImg: pimg6,
        link: 'https://theprint.in/ani-press-releases/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements/1964948/',
        alt: 'The Print',
    },
    {
        pImg: pimg7,
        link: 'https://www.theweek.in/wire-updates/business/2024/02/13/dcm73-fortunna-global.html',
        alt: 'The Week',
    },
    {
        pImg: pimg1,
        link: 'https://www.forbesindia.com/article/brand-connect/leadership-effectiveness-and-social-responsibility-cornerstones-of-excellence/92727/1',
        alt: 'Forbes India',
    },
    // {
    //     pImg: pimg8,
    // },
    // {
    //     pImg: pimg9,
    // },
    {
        pImg: pimg10,
        link: 'https://www.aninews.in/news/business/business/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180052/',
        alt: 'ANI News',
    },
    {
        pImg: pimg11,
        link: 'https://www.bloomberg.com',
        alt: 'Bloomberg News',
    },
    {
        pImg: pimg12,
        link: 'https://www.moodysanalytics.com',
        alt: 'Moody Analytics',
    },
    {
        pImg: pimg13,
        link: 'https://www.crunchbase.com',
        alt: 'Crunchbase',
    },
    {
        pImg: pimg14,
        link: 'https://www.menafn.com',
        alt: 'Menafn',
    },
    // {
    //     pImg: pimg15,
    // },
];


const PartnerSection2 = (props) => {
    return (
        <section className="clients-one">
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading">Featured On</h2>
                </div>
                <div className="clients-two_slide d-flex">
                    {partners.map((partner, index) => (
                        <div className="clients-two_column" key={index}>
                            <a href={partner.link} target="_blank" rel="noopener noreferrer">
                                <img src={partner.pImg} alt={partner.alt} style={{width: '170px'}} />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PartnerSection2;