import React from 'react';
import { Link } from 'react-router-dom'



const pricing = [
    {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Standard Pass',
        price: '120',
        day: 'FIRST DAY',
    },
    {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Flexible Pass',
        price: '220',
        active: 'active',
        day: 'SECOND DAY',
    },
    {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Business Pass',
        price: '320',
        day: 'THIRD DAY',
    },


]


const Pricing3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="price-two">
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">Conference Ticket Price</div>
                    <h2 className="sec-title_heading">Conference Tickets Now</h2>
                </div>
                <div className="row clearfix">
                    {pricing.map((pricing, ptem) => (
                        <div className="price-block_two col-lg-4 col-md-6 col-sm-12" key={ptem}>
                            <div className="price-block_two-inner">
                                <span className="price-block_two-color_layer"></span>
                                <div className="price-block_two-time">{pricing.day}</div>
                                <div className="price-block_two-title">{pricing.title}</div>
                                <div className="price-block_two-date">${pricing.price}</div>
                                <div className="price-block_two-content">
                                    <ul className="price-block_two-options">
                                        <li><strong>{pricing.li1}</strong>{pricing.li2}</li>
                                        <li className="light"><strong>{pricing.li3}</strong>{pricing.li4}</li>
                                        <li className="light"><strong>{pricing.li5}</strong>{pricing.li6}</li>
                                    </ul>
                                    <div className="price-block_two-button">
                                        <Link onClick={ClickHandler} to="/price" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Buy Ticket</span>
                                                <span className="text-two">Buy Ticket</span>
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="price-block_two-charges">Our prices includes 10% charges</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    );
}

export default Pricing3;