import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/The-Forttuna-Global-Excellence-Awards-Business-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    // useEffect(() => {
    //     document.title = 'Forttuna Global Excellence Awards : International Business Award';
    // }, []);
    return (
        <>
         <Helmet>
                <meta name="description" content="Celebrate exceptional achievements and join a community of industry champions in the international business awards." />
                <meta property="og:title" content="Forttuna Global Excellence Awards : International Business Award" />
                <meta property="og:description" content="Celebrate exceptional achievements and join a community of industry champions in the international business awards." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="International Business Awards" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: International Business Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna International Business Awards" />
                        </div>
                        <h4 style={{color: '#fff'}}>Forttuna Global Excellence Awards : International Business Award</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led International Business Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The Forttuna Global Excellence Awards are ready to celebrate your success on the international stage. Our prestigious International Business Awards category seeks to honor exceptional companies who are redefining the landscape of global commerce. From innovative exporters to industry-disrupting startups, showcase your commitment to excellence and pioneering spirit. Be recognized for your contribution to international trade and join a community of <a href="https://board.fastcompany.com/blog/visionary-leader-qualities">visionary leaders</a> shaping the future of business.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury-led and merit-based business awards program recognizing exceptional achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Award of Excellence to honor individuals and companies through business awards making a significant impact on the global landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Be Recognized as a Global Leader:</strong> Celebrate exceptional achievements and join a community of industry champions in the international business awards.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong> Merit-based selection by a distinguished panel of industry leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Shape the Future of Trade:</strong> Showcase your contribution to the evolution of international commerce.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connect and Collaborate:</strong> Fosters connections and collaboration for a thriving global business landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain Global Recognition:</strong> Elevate your brand and achievements on the international stage.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}>International Business Awards Categories:</h4>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>B. Automobile</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>C. Aviation</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>D. Finance and Insurance</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>E. Fashion and Beauty</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>F. Entertainment and Media</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>G. Education</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>H. Hospitality</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>I. Manufacturing</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>J. Marketing</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>K. Market Research</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><a href="https://hbr.org/1998/11/how-venture-capital-works">Venture Capitalist</a> of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>L. Human Resources</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>M. Real Estate</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>N. SME&rsquo;s</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>O. Technology</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>P. Textile</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p>Learn more about the other <a href="https://forttuna.co/award-categories/">categories</a>.</p>
                        <h6 style={{color: '#fff'}}>Ready to shine a light?</h6>
                        <p>Gain recognition for your contribution to international trade and connect with a network of industry leaders shaping the future of global business at the International Business Awards.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now</a>!</p>
                        <p><a href="https://participate.forttuna.co/">Join us</a> in India!</p>
                        <p><a href="https://forttuna.co/awards/marketing-awards/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/business-awards/">Business Awards.</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
