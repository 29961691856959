
import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD30/BlogD30';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Your Guide to Winning a Prestigious UK Business Award (2024)';
    }, []);
    return (
        <>
           <Helmet>
                <meta name="description" content="Selecting and securing the right business award, such as the UK Business Award, is key to optimizing your efforts and standing out." />
                <meta property="og:title" content="Your Guide to Winning a Prestigious UK Business Award (2024)" />
                <meta property="og:description" content="Selecting and securing the right business award, such as the UK Business Award, is key to optimizing your efforts and standing out." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="keywords" content="UK Business Award" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='Your Guide to Winning a Prestigious UK Business Award (2024)' pagesub={''} pageTitle2={''}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default BlogDetails;
