import React from 'react'
import bg from '../../images/main-slider/image-2.png'
import { Link } from 'react-router-dom'

const WhyAttend = (props) => {

    const Event = [
        {
            title: 'Recognition',
            icon: 'flaticon-thumbs-up'
        },
        {
            title: 'Inspiration',
            icon: 'flaticon-creative-mind'
        },
        {
            title: 'Global Platform',
            icon: 'flaticon-training'
        },
        // {
        //     title: 'Global Platform',
        //     icon: 'flaticon-hand-shake'
        // },
        {
            title: 'Media/Press Coverage',
            icon: 'flaticon-mic'
        },
        {
            title: 'Awards Gala Night',
            icon: 'flaticon-poinsettia'
        },
        {
            title: 'Networking Opportunities',
            icon: 'flaticon-hand-shake'
        },


    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="event-two" style={{ backgroundImage: `url(${bg})` }}>
            <div className="auto-container">
                <div className="sec-title light centered title-anim">
                    <div className="sec-title_title">Join Our Excellence Program</div>
                    <h2 className="sec-title_heading">Why Participate?</h2>
                    <div className="sec-title_text">
                                       Welcome to the Forttuna Global Excellence Awards, where we celebrate the remarkable achievements <br/> and contributions of individuals and organisations across various sectors.
                                    </div>
                </div>
                <div className="clearfix">
                    {Event.map((event, eitem) => (
                        <div className="event-block_two" key={eitem}>
                            <div className="event-block_two-inner">
                                <div className={`event-block_two-icon fi ${event.icon}`}></div>
                                <h5 className="event-block_two-title" style={{color : '#fff'}}>{event.title}</h5>
                                {/* <h5 className="event-block_two-title"><Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop">{event.title}</Link></h5> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    )
}

export default WhyAttend;