import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/Health-1.webp'
import abimg2 from '../../images/resource/Health-2.webp'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one business-on">
            <div className="auto-container" >
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                <h2 className="sec-title_heading">Our Vision</h2>
                            </div>
                            {/* <div className="business-one_bold-text">Grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures together</div> */}
                            <div className="business-one_text">
                            <p>The Forttuna Global Excellence Awards Program understand that health encompasses the most crucial facet of our life, be it physical or mental. So, the excellence awards recognize and shower accolades on the professionals of the healthcare and wellness sector. These global awards celebrate and cherish these extraordinary and outstanding individuals, organizations, and institutions for their remarkable contributions.<br />The Forttuna <a href="https://forttuna.co/health-awards/" target="_blank" rel="noopener nofollow noreferrer">Modern Healthcare Awards</a> position you as a leader at the forefront of healthcare and wellness innovation. The Forttuna Doctor Award demonstrates your commitment to excellence, patient well-being, and cutting-edge treatments and attracts high-performing colleagues and patients.<br />The Forttuna <a href="https://forttuna.co/awards/health-services-awards/" target="_blank" rel="noopener nofollow noreferrer">Health Industry Award</a> communicates a commitment to patient-centered care and the highest quality standards. The Forttuna <a href="https://forttuna.co/wellness-awards/" target="_blank" rel="noopener nofollow noreferrer">Wellness Awards</a> grants you access to these exclusive networking opportunities. The Forttuna Healthcare Marketing Awards can attract potential investors or grantors for innovative healthcare and wellness initiatives.</p>
                            </div>
                            <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/schedule" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Event Schedule</span>
                                        <span className="text-two">Event Schedule</span>
                                    </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;