import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import pIcon1 from '../../images/background/Blog.jpg'
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import PageTitle from '../../components/pagetitle/PageTitle'
import BlogList from '../../components/BlogList/BlogList.js'
import Navbar from '../../components/Navbar/Navbar';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const BlogPage =() => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Blogs';
    }, []);
    return(
        <Fragment>
            <Helmet>
                <meta name="description" content="Forttuna Global Excellence Awards blog helps you stay updated on the latest news and insights from industry experts." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Blogs" />
                <meta property="og:description" content="Forttuna Global Excellence Awards blog helps you stay updated on the latest news and insights from industry experts." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Latest News</div>
                            <h2 className="page-title_heading">Latest News</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>The FFTB Show</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            {/* <PageTitle pageTitle={'Latest News'} pagesub={'Blog'} pageTitle2={'Blog'}/>  */}
            <BlogList/>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogPage;

