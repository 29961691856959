import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Aviation-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    // useEffect(() => {
    //     document.title = 'The Forttuna Global Excellence Awards: Aviation Awards';
    // }, []);
    return (
        <><Helmet>
        <meta name="description" content="Celebrate exceptional achievements by businesses and individuals in the aviation awards category on the global stage." />
        <meta property="og:title" content="The Forttuna Global Excellence Awards: Aviation Awards" />
        <meta property="og:description" content="Celebrate exceptional achievements by businesses and individuals in the aviation awards category on the global stage." />
        <meta property="og:image" content={hero1} />
        <meta property="og:url" content={hero1  } />
        <meta name="keywords" content="aviation awards" />
        <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Aviation Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Aviation Awards" />
                        </div>
                        <h4 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Aviation Awards</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Aviation Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The Forttuna Global Excellence Awards invites you to take flight in the prestigious Aviation Awards category. This program honors exceptional achievements across the aviation industry, recognizing</p>
                        <p>innovators who are revolutionizing <a href="https://en.wikipedia.org/wiki/Air_travel">air travel.</a> From groundbreaking safety measures to exceptional customer service, showcase your commitment to keeping the skies friendly and reaching new heights of excellence.</p>
                        <h2 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h2>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Recognition:</strong> Celebrate exceptional achievements by businesses and individuals in the aviation awards category on the global stage.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Jury:</strong> Merit-based selection overseen by industry leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Cross-Industry Impact:</strong> Awards across diverse fields, recognizing a broad spectrum of excellence.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Beyond Recognition:</strong> Fosters connections and collaboration for a global impact.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Reach:</strong> Gain recognition and showcase your achievements on the international stage.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain international recognition for your company's innovative ideas and leadership in the aviation awards category.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase your team's talent and inspire others through your achievements. Network with industry leaders at our prestigious ceremony, fostering valuable connections.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Increase your brand profile and attract new opportunities by being recognized on a global scale.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Secure significant media exposure that propels your achievements into the public eye and positions you as a leader in Aviation.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A distinguished panel of international jury experts ensures a fair and merit-based evaluation, granting you the recognition you deserve.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}>Aviation Awards Categories :</h4>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p>Learn more about the other <a href="https://forttuna.co/award-categories/">prestigious categories</a>.</p>
                        <h5 style={{color: '#fff'}}>Ready to shine a light?</h5>
                        <h6 style={{color: '#fff'}}>Nominate yourself or someone you know for recognition.</h6>
                        <p>Join us in celebrating exceptional achievements in the aviation industry at the prestigious Forttuna Global Excellence Awards - Aviation Category.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now</a>! Be a part of the <a href="https://participate.forttuna.co/">Forttuna Entrepreneur Awards in India</a>.</p>
                        <p><a href="https://forttuna.co/awards/fintech-award/">Learn More</a> about the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/architecture-awards/">Architecture Awards</a>!</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
