import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Health-2023.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Jury2023 from '../../components/Jury2023/Team';
import Features from '../../components/Features';
import Health2023 from '../../components/Health2023/Health2023';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const AboutPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Health & Wellness Edition 2023';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Healthcare Excellence Awards recognizes and honors the first line of defense against illnesses, our own angels with stethoscopes." />
            <meta property="og:title" content="Forttuna Global Excellence Awards | The Team of Global Awards" />
            <meta property="og:description" content="Forttuna Healthcare Excellence Awards recognizes and honors the first line of defense against illnesses, our own angels with stethoscopes." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">2023 Health & Wellness Leaders Edition </div>
                            <h2 className="page-title_heading">2023 Health & Wellness Leaders Edition </h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Health</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <Health2023 />
            <Jury2023 />
            <VideoArea />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default AboutPage;
