import timg from '../images/resource/Randeer-Sood.jpg'

import tsimg from '../images/resource/Randeer-Sood.jpg'



const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Randhir Sud',
      slug: 'Padmashri Dr. Randhir Sud',
      title: "Chairman, Institute of Digestive and Hepatobiliary Sciences, Medanta the Medicity, NCR, India",
   },

]

export default Teams;