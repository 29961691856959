import timg from '../images/resource/riitesh-mehtaa.webp'
// import timg0 from '../images/resource/speaker-1.jpg'

import tsimg from '../images/resource/riitesh-mehtaa.webp'
// import tsimg0 from '../images/resource/speaker-1.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Riitesh Mehtaa',
      slug: 'Riitesh Mehtaa',
      title: "Serial Entrepreneur & Business Advisor",
   },
   // {
   //    Id: '2',
   //    tImg: timg0,
   //    tsImg: tsimg0,
   //    name: 'Person2',
   //    slug: 'Person2',
   //    title: "The Forttuna Group",
   // },
   // {
   //    Id: '8',
   //    tImg: timg8,
   //    tsImg: tsimg8,
   //    name: 'Harnold Min',
   //    slug: 'Harnold-Min',
   //    title: 'Starbucks CEO',
   // },

]

export default Teams;