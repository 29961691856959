import React, { Fragment, useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Newsroom.jpg';
import Navbar from '../../components/Navbar/Navbar';
import VideoArea from '../../components/VideoArea/VideoArea';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ModalVideo from 'react-modal-video';
import mImg1 from '../../images/gallery/video-1-zee-tv.webp';
import mImg2 from '../../images/gallery/video-2-copy.webp';
import pimg1 from '../../images/news/1.webp'
import pimg2 from '../../images/news/2.webp'
import pimg3 from '../../images/news/3.webp'
import pimg4 from '../../images/news/4.webp'
import pimg5 from '../../images/news/5.webp'
import pimg6 from '../../images/news/6.webp'
import pimg7 from '../../images/news/7.webp'
import pimg8 from '../../images/news/8.webp'
import pimg9 from '../../images/news/9.webp'
import pimg10 from '../../images/news/10.webp'
import pimg11 from '../../images/news/11.webp'
import pimg12 from '../../images/news/12.webp'
import pimg13 from '../../images/news/13.webp'
import pimg14 from '../../images/news/14.webp'
import pimg15 from '../../images/news/15.webp'
import pimg16 from '../../images/news/16.webp'
import pimg17 from '../../images/news/17.webp'
import pimg18 from '../../images/news/18.webp'
import pimg19 from '../../images/news/19.webp'
import pimg20 from '../../images/news/20.webp'
import pimg21 from '../../images/news/21.webp'
import pimg22 from '../../images/news/22.webp'
import pimg23 from '../../images/news/23.webp'
import pimg24 from '../../images/news/24.webp'
import pimg25 from '../../images/news/25.webp'

import pimg26 from '../../images/news/Benzianga.png'
import pimg27 from '../../images/news/Menafn.png'
import pimg28 from '../../images/news/Middle-East-news-.png'
import pimg29 from '../../images/news/Business-Time-General.png'
import pimg30 from '../../images/news/North-America.png'
import pimg31 from '../../images/news/Middle-east-news-General.png'
import pimg32 from '../../images/news/Economic-Policy-Times.png'
import pimg33 from '../../images/news/News-Break-Middle-East.png'
import pimg34 from '../../images/news/US-National-Times.png'
import pimg35 from '../../images/news/Business-Gazette.png'
import pimg36 from '../../images/news/US-Time-Gazette.png'
import pimg37 from '../../images/news/Business-Herald.png'
import pimg38 from '../../images/news/American-Business-Time.png'
import pimg39 from '../../images/news/Business-UAE-Daily.png'
import pimg40 from '../../images/news/AP.png'
import pimg41 from '../../images/news/Business-News-TOday.png'
import pimg42 from '../../images/news/Fox-news-channel.png'
import pimg43 from '../../images/news/NBC-News.png'
import pimg44 from '../../images/news/CBS-News.png'
// import pimg20 from '../../images/news//British-Colombia-News.webp'

const partners = [
    {
        pImg: pimg1,
        link : 'https://www.forbesindia.com/article/brand-connect/leadership-effectiveness-and-social-responsibility-cornerstones-of-excellence/92727/1',
    },
    {
        pImg: pimg2,
        link : 'https://www.dnaindia.com/health/report-forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements-and-innovation-3077752',
    },
    {
        pImg: pimg3,
        link : 'https://www.khaleejtimes.com/kt-network/forttuna-global-excellence-awards-2023-a-resounding-success-celebrating-healthcare-pioneers',
    },
    {
        pImg: pimg4,
        link : 'https://www.ptinews.com/press-release/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements/1287598',
    },
    {
        pImg: pimg5,
        link : 'https://www.latestly.com/agency-news/business-news-forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements-5757482.html',
    },
    {
        pImg: pimg6,
        link : 'https://theprint.in/ani-press-releases/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements/1964948/',
    },
    {
        pImg: pimg7,
        link : 'https://www.theweek.in/wire-updates/business/2024/02/13/dcm73-fortunna-global.html',
    },
    {
        pImg: pimg8,
        link : 'https://www.whitehousenewstime.com/news/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180046/',
    },
    {
        pImg: pimg9,
        link : 'https://news.abplive.com/brand-wire/forttuna-global-excellence-awards-2023-a-gala-celebration-of-healthcare-achievements-1662548-',
    },
    {
        pImg: pimg10,
        link : 'https://www.aninews.in/news/business/business/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180052/',
    },
    {
        pImg: pimg12,
        link : 'https://asiannews.in/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements/#google_vignette%20_blank',
    },
    {
        pImg: pimg13,
        link : 'https://www.bignewsnetwork.com/news/274125121/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements',
    },
    {
        pImg: pimg14,
        link : 'https://markets.businessinsider.com/news/stocks/forttuna-global-excellence-awards-announces-business-leaders-edition-2024-in-dubai-1033234526',
    },
    {
        pImg: pimg15,
        link : 'https://www.business-standard.com/content/press-releases-ani/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements-124021400005_1.html',
    },
    {
        pImg: pimg16,
        link : 'https://m.dailyhunt.in/news/india/english/loktej+english-epaper-loktejen/forttuna+global+excellence+awards+2023+celebrating+healthcare+achievements-newsid-n583072218?sm=Y',
    },
    {
        pImg: pimg17,
        link : 'https://www.englandnewsportal.com/news/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180046/',
    },
    {
        pImg: pimg18,
        link : 'https://gulfnews.com/business/corporate-news/forttuna-global-excellence-awards-2023-honoring-healthcare-heroes-1.1709713317163',
    },
    {
        pImg: pimg19,
        link : 'https://www.hindustantimes.com/brand-stories/forttuna-global-excellence-awards-2023-recognizes-outstanding-achievements-in-healthcare-101707737758393.html',
    },
    {
        pImg: pimg20,
        link : 'https://www.britishcolumbiatimes.com/news/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180046/',
    },
    {
        pImg: pimg21,
        link : 'https://www.livemint.com/brand-stories/forttuna-global-excellence-awards-2023-celebrates-healthcare-innovations-and-achievements-11707999498443.html',
    },
    {
        pImg: pimg22,
        link : 'https://www.francenetworktimes.com/news/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180046/',
    },
    {
        pImg: pimg23,
        link : 'https://www.usworldtoday.com/news/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180046/',
    },
    {
        pImg: pimg24,
        link : 'https://www.torontosuntimes.com/news/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements20240213180046/',
    },
    {
        pImg: pimg25,
        link : 'https://www.uniindia.com/forttuna-global-excellence-awards-2023-celebrating-healthcare-achievements/pnn/news/3143324.html',
    },
    {
        pImg: pimg27,
        link : 'https://menafn.com/1108697964/Forttuna-Global-Excellence-Awards-Celebrate-Exceptional-Leaders-In-Business-And-Healthcare',
    },
    {
        pImg: pimg26,
        link : 'https://www.benzinga.com/content/40965799/forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg41,
        link : 'https://business.einnews.com/pr_news/744240285/forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg40,
        link : 'https://apnews.com/press-release/ein-presswire-newsmatics/dubai-cbc48e556c10e85786323065ae0e9ae4',
    },
    {
        pImg: pimg39,
        link : 'https://www.uaebusinessdaily.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg38,
        link : 'https://www.americanbusinesstimes.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg42,
        link : 'https://www.fox44news.com/business/press-releases/ein-presswire/744240285/forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg43,
        link : 'https://www.nbc4i.com/business/press-releases/ein-presswire/744240285/forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare/',
    },
    {
        pImg: pimg44,
        link : 'https://www.wrbl.com/business/press-releases/ein-presswire/744240285/forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg35,
        link : 'https://www.thebusinessgazetteonline.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg31,
        link : 'https://www.middleeastnewsjournal.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg36,
        link : 'https://www.ustimesgazette.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg34,
        link : 'https://www.usnationaltimes.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg33,
        link : 'https://www.newsbreakmiddleeast.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg32,
        link : 'https://www.economicpolicytimes.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg30,
        link : 'https://www.northamericatoday.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg29,
        link : 'https://www.businesstimesjournal.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg28,
        link : 'https://www.middleeastnewsnetwork.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    {
        pImg: pimg37,
        link : 'https://www.businessheraldonline.com/article/744240285-forttuna-global-excellence-awards-celebrate-exceptional-leaders-in-business-and-healthcare',
    },
    
    
    
    
//     {
//         pImg: pimg20,
//     },
]
const HomePage = () => {
    const [isOpen, setOpen] = useState(false);
    const [isOpen2, setOpen2] = useState(false);
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Our Aim';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Global Excellence Awards aims to attract a diverse and accomplished demographic that spans various industries and sectors." />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Our Aim" />
            <meta property="og:description" content="Forttuna Global Excellence Awards aims to attract a diverse and accomplished demographic that spans various industries and sectors." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Newsroom</div>
                            <h2 className="page-title_heading">Newsroom</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='row' style={{margin: '80px 60px'}}>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg1})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className="auto-container video" style={{ backgroundImage: `url(${mImg2})`}}>
                            <div className="sec-title title-anim light centered" style={{marginTop: '97px'}}>
                                <button onClick={() => setOpen2(true)} className="lightbox-video video-one_play">
                                    <span className="fa fa-play"><i className="ripple"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="LZv_P2mimGw" onClose={() => setOpen(false)} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen2} videoId="SYcphr6lTVs" onClose={() => setOpen2(false)} />
            </section>
            <section className="clients-one">
                <div className="auto-container">
                        {/* <h2 className="sec-title_heading">Partners</h2> */}
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="row">
                                {partners.map((partner, pitem) => (
                                    <div className="clients-one_column col-lg-3 col-md-3 col-sm-4" key={pitem}>
                                        <div  style={{ padding: '30px', borderRadius: '20px', border: '2px solid #c99c18' }}>
                                            <a href={partner.link} target='_blank'><img src={partner.pImg} alt="" /></a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <VideoArea />
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    );
};

export default HomePage;
