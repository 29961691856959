import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
// import Logo from '../../images/forttuna-logo2.png'
import Logo from '../../images/Forttuna-1.png'
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
        // submenu: [
        //     {
        //         id: 11,
        //         title: 'Homepage 01',
        //         link: '/home'
        //     },
        //     {
        //         id: 12,
        //         title: 'Homepage 02',
        //         link: '/home-2'
        //     },
        //     {
        //         id: 13,
        //         title: 'Homepage 03',
        //         link: '/home-3'
        //     },
        //     {
        //         id: 14,
        //         title: 'Home Onepage 01',
        //         link: '/home-onepage-1'
        //     },
        //     {
        //         id: 15,
        //         title: 'Home Onepage 02',
        //         link: '/home-onepage-2'
        //     },
        //     {
        //         id: 16,
        //         title: 'Home Onepage 03',
        //         link: '/home-onepage-3'
        //     },
        // ]
    },

    {
        id: 4,
        title: 'Global Edition',
        // link: '/about',
        submenu: [
            {
                id: 41,
                title: 'Health & Wellness Leaders Edition',
                link: '/health-wellness-edition/',
            },
            {
                id: 42,
                title: 'Business Leaders Edition',
                link: '/business-leaders-edition'
            },
            {
                id: 43,
                title: 'Special Invitees',
                link: '/special-invitees/'
            },
            {
                id: 44,
                title: 'The Jury',
                link: '/the-jury/'
            },
            {
                id: 46,
                title: 'Participation Guidelines',
                link: '/participation-guidelines/'
            },
            {
                id: 47,
                title: 'Categories',
                link: '/award-categories/'
            },
        ]
    },

    {
        id: 6,
        title: 'India Edition',
        // link: '/speaker',
        submenu: [
            {
                id: 61,
                title: 'Health & Wellness Leaders Edition',
                link: '/health-wellness-edition-india/'
            },
            {
                id: 62,
                title: 'Business Leaders Edition',
                link: '/business-leaders-edition-india/'
            },
            {
                id: 63,
                title: 'Special Invitees',
                link: '/special-invitees-india/'
            },
            {
                id: 64,
                title: 'The Jury',
                link: '/the-jury-india/'
            },
            {
                id: 65,
                title: 'Participation Guidelines',
                link: '/participation-guidelines-india/'
            },
            {
                id: 66,
                title: 'Categories',
                link: '/award-categories-india/'
            },
        ]
    },
    {
        id: 7,
        title: 'Past Editions',
        link: '',
        
        submenu: [
            {
                id: 71,
                title: '2023 Edition Special Invitees',
                link: '/special-guests/'
            },
            {
                id: 72,
                title: '2023 Edition Partners',
                link: '/our-partners/'
            },
            {
                id: 73,
                title: '2023 Edition Winners',
                link: '/past-winners/'
            }
        ]
    },
    {
        id: 5,
        title: 'Gallery',
        link: '/gallery',
    },
    {
        id: 5,
        title: 'Coverage',
        submenu: [
            {
                id: 51,
                title: 'The FFTB Show',
                link: '/the-fftb-show/'
            },
            {
                id: 52,
                title: 'News',
                link: '/newsroom/'
            },
            {
                id: 53,
                title: 'Blogs',
                link: '/blog/'
            },
            {
                id: 54,
                title: 'Awards',
                link: '/awards/'
            }
        ]
    },
    {
        id: 8,
        title: "Faq's",
        link: '/faq/',
    },
    {
        id: 9,
        title: "Participate Now",
        link: 'https://participate.forttuna.co/#form',
    },


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className={`mobile-menu-wrap ${menuActive ? "mobile-menu-visible" : ""}`}>
            <div className="mobile-nav-toggler" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-menu"></span></div>
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={() => setMenuState(!menuActive)}></div>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-multiply"></span></div>
                <nav className="menu-box">
                <div class="nav-logo">
                    <a href="/" contenteditable="false" style={{Cursor: 'pointer'}}><img src={Logo} alt="" title="" /></a>
                </div>

                    <div className="menu-outer">
                        <ul className="navigation">

                            {menus.map((item, mn) => {
                                return (
                                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                        {item.submenu ?
                                            <Fragment>
                                                <li>
                                                    <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                                        <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                                    </p>
                                                </li>
                                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                                    <List className="subMenu">
                                                        <Fragment>
                                                            {item.submenu.map((submenu, i) => {
                                                                return (
                                                                    <ListItem key={i}>
                                                                        <Link onClick={ClickHandler} className="active"
                                                                            to={submenu.link}>{submenu.title}</Link>
                                                                    </ListItem>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    </List>
                                                </Collapse>
                                            </Fragment>
                                            : <Link className="active"
                                                to={item.link}>{item.title}</Link>
                                        }
                                    </ListItem>
                                )
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MobileMenu;