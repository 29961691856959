import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import pIcon1 from '../../images/background/Who-We-Are-1.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre';
import FunFact from '../../components/FunFact';
// import Features from '../../components/Features';
import WhyAttendS2 from '../../components/WhyAttendS2/WhyAttendS2';
// import Business from '../../components/Business/Business';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
// import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const AboutPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Who We Are';
    }, []); // The empty array ensures this runs only once when the component mounts
    return (
        <>
        <Helmet>
            <meta name="description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honouring outstanding achievements across various industries." />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Who We Are" />
            <meta property="og:description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honouring outstanding achievements across various industries." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Who We Are</div>
                            <h2 className="page-title_heading">Who We Are</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Who We Are</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <WhoWeAre />
            <FunFact />
            {/* <Features /> */}
            <WhyAttendS2 />
            {/* <Business /> */}
            <VideoArea />
            <PartnerSection />
            {/* <VenueSection /> */}
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default AboutPage;
