import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Health-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Health Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="The Health Awards recognize and celebrate the exceptional contributions of individuals and organizations transforming the healthcare landscape" />
            <meta property="og:title" content="Forttuna Global Excellence Awards: Health Awards" />
            <meta property="og:description" content="The Health Awards recognize and celebrate the exceptional contributions of individuals and organizations transforming the healthcare landscape" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="Health Awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: Health Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Health Awards recognition" />
                        </div>
                        <h4 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Health Awards</strong></h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}>First-ever Jury Led Health Awards: Be Recognized on the World Stage</h1>
                        <p>The Forttuna Global Excellence Health Awards recognize and celebrate the exceptional contributions of individuals and organizations transforming the healthcare landscape. By honoring pioneering initiatives, innovative solutions, and outstanding patient care, the awards spotlight the dedication and expertise driving advancements in global health and wellness.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Recognition:</strong> Honor exceptional achievements in healthcare and wellness.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Evaluation:</strong> Benefit from a rigorous judging process by healthcare leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Impactful Contributions:</strong> Celebrate outstanding contributions to improving global health.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Networking Opportunities:</strong> Connect with other healthcare innovators and collaborate.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Leadership:</strong> Gain international recognition for your healthcare initiatives.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate? (Insert pictures of past awardees on the side)</strong></h3>
                        <p>.</p>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Showcase Excellence:</strong> Position your organization as a healthcare industry leader.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expand Your Network:</strong> Build valuable partnerships and open new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire Future Leaders:</strong> Motivate the next generation of healthcare professionals.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connect with Industry Peers:</strong> Network with healthcare innovators at our exclusive event.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Enhance Your Reputation:</strong> Gain widespread recognition for your healthcare achievements.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong> Receive expert recognition for your contributions to healthcare.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}><strong>Health Awards</strong> <a href="https://forttuna.co/award-categories/">Categories</a> <strong>:</strong></h4>
                        <p><strong>A. Ayush, Spa, Wellness and Yoga Category</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ayurveda Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Spa/Medical Resort Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Yoga Centre Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Centre/ Retreat Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        </ol>
                        <p><strong>B. Company and Brands</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Health Insurance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Long-Tem Care Provider Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pharmacy Retail Chain Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Equipment Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Start-up Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Public Healthcare Initiative Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Medical Product Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Insurance and Finance Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Education Company Of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Veterinary Company Of the Year</li>
                        </ol>
                        <p><strong>C. CXO&rsquo;s Health &amp; Wellness</strong></p>
                        <p><strong>D. Hospital &amp; Clinic Category</strong></p>
                        <p><strong>E. Individual Category</strong></p>
                        <p><strong>F. Medical College/Institute Category</strong></p>
                        <h5 style={{color: '#fff'}}><strong>Ready to shine a light?</strong></h5>
                        <h6 style={{color: '#fff'}}><strong>Nominate yourself or someone you know for recognition.</strong></h6>
                        <p>Let's celebrate your achievements together at the prestigious Forttuna Global Excellence Health Awards. Join us in honoring the pioneers transforming healthcare and recognize your organization's exceptional contributions to improving global health and wellness.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p><a href="https://participate.forttuna.co/">Nominate</a> and be a part of Forttuna India Awards!</p>
                        <p><a href="https://forttuna.co/awards/business-excellence-awards/">Learn More</a> About the Excellence Award.</p>
                        <p>Know About <a href="https://forttuna.co/awards/nurse-awards/">Forttuna Nurse Awards</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
