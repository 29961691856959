import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/business-1.jpg'
import abimg2 from '../../images/resource/business-2.jpg'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one business-on">
            <div className="auto-container" >
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                {/* <div className="sec-title_title">Become Succesfuly day</div> */}
                                <h2 className="sec-title_heading">Our Vision</h2>
                            </div>
                            <div className="business-one_text"><p>The Forttuna Global Excellence Awards Program understand that health encompasses the most crucial facet of our life, be it physical or mental. Healthcare is one of the most righteous and noble professions known to mankind. So, we make a way and take this opportunity as excellence awards to recognize and shower <a href="https://forttuna.co/award-categories/">prestigious accolades</a> on people who work in the shadow of anonymity to enrich and improve the quality of our lives. These global awards are to honour the first line of defence against illnesses, our very own angels with stethoscopes. These excellence awards are to <a href="https://participate.forttuna.co/">celebrate and cherish</a> these extraordinary and outstanding individuals, organisations, and institutions in the medical industry and to recognize their remarkable contributions, achievements, and innovations in improving the services and overall healthcare practices.</p></div>


                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;