import React from 'react'
import bg from '../../images/background/pattern-6.png'
import bg2 from '../../images/background/pattern-10.png'
import { Link } from 'react-router-dom'

const WhyAttendS2 = (props) => {

    const Service = [
        {
            title: 'Global Participation',
            des: 'The Forttuna Global Excellence Awards is the premier global platform for recognizing outstanding achievements across diverse industries. Forttuna Global Excellence Awards is the go-to hub where excellence is celebrated, and innovators from around the world are acknowledged for their significant contributions.',
            icon: 'flaticon-planet-earth'
        },
        {
            title: 'Networking Opportunity',
            des: 'The Forttuna Global Excellence Awards extend beyond a mere recognition ceremony; they offer a unique networking opportunity for professionals, organisations, and thought leaders. Our events provide a space for industry peers to connect, share insights, and foster collaborations that transcend boundaries.',
            icon: 'flaticon-connection'
        },
        {
            title: 'Stellar Lineup',
            des: 'Join a stellar lineup of industry leaders, innovators, and visionaries who have been recognized by the Forttuna Global Excellence Awards. Elevate your profile, gain global visibility, and become part of an exclusive community dedicated to excellence.',
            icon: 'flaticon-idea'
        },
        {
            title: 'Extensive PR Coverage',
            des: 'Forttuna Global Excellence Awards has garnered extensive PR coverage, with features in renowned publications, interviews on influential platforms, and contributions to industry discussions. The media recognizes Forttuna\'s commitment to highlighting achievements and fostering a culture of excellence.',
            icon: 'flaticon-video-camera'
        },
        {
            title: 'Unmatched Recognition',
            des: 'Winners of The Forttuna Global Excellence Awards receive not only a prestigious accolade but also gain public visibility, establishing themselves as leaders and innovators in the healthcare sector.',
            icon: 'flaticon-trophy-3'
        },
        {
            title: 'International Jury',
            des: 'Our International Jury is the cornerstone of Forttuna\'s commitment to recognizing global excellence. Comprising seasoned professionals, industry leaders, and subject matter experts from various fields, the jury brings a wealth of experience and diverse perspectives to the evaluation process.',
            icon: 'flaticon-book'
        },
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="services-one">
            {/* <div className="services-one_pattern-one" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="services-one_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title centered title-anim">
                    {/* <div className="sec-title_title">Join The Event</div> */}
                    <h2 className="sec-title_heading">Why Attend Event?</h2>
                </div>
                <div className="row clearfix">
                    {Service.map((service, sitem) => (
                        <div className="service-block_one col-lg-4 col-md-6 col-sm-12" key={sitem}>
                            <div className="service-block_one-inner" style={{height: '465px'}}>
                                <div className={`service-block_one-icon fi ${service.icon}`}></div>
                                <h4 className="service-block_one-heading"><Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop">{service.title}</Link></h4>
                                <div className="service-block_one-text">{service.des}</div>
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="service-block_one-arrow fi flaticon-next-2"></Link>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>

    )
}

export default WhyAttendS2;