import React, { useState } from 'react';
import Sub1 from '../../images/background/06.webp'


const Subscribe2 = (props) => {

    const [isDisabled, setIsDisabled] = useState(false);

    const handleClick = () => {
        // Disable the button after it's clicked
        setIsDisabled(true);
        // Handle your subscription logic here
        console.log("Subscribed!");
    };

    return (
        <section className="subscribe-two" style={{ backgroundImage: `url(${Sub1})` }}>
            <div className="auto-container">
                <div className="inner-container">
                    <div className="row clearfix">
                        <div className="subscribe-two_title-column col-lg-6 col-md-12 col-sm-12">
                            <div className="subscribe-two_title">Get The Latest Updates</div>
                            <h3 className="subscribe-two_heading">Subscribe Now!</h3>
                        </div>
                        <div className="form-column col-lg-6 col-md-12 col-sm-12">
                            <div className="subscribe-box style-two">
                                <form action="https://forttuna.co/subscribe-now/" method="POST">
                                    <div class="form-group">
                                        <input type="text" name='email' id="email" placeholder="Your Email Address"/>
                                        <button type="submit" id="subscribeButton">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default Subscribe2;