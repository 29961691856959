import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Speakers from '../../components/Speakers/Speakers';
import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer2 from '../../components/footer2/Footer2';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';

const SpeakerPage = (props) => {

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Teams'} pagesub={'Team'} pageTitle2={'Team'}/>
            <Speakers/>
            <VenueSection />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default SpeakerPage;
