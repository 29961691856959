import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Fintech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards:  Fintech Breakthrough Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="A prestigious jury led award, the Fintech Breakthrough Awards celebrate outstanding achievements in fintech innovation." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards:  Fintech Breakthrough Awards" />
            <meta property="og:description" content="A prestigious jury led award, the Fintech Breakthrough Awards celebrate outstanding achievements in fintech innovation." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="Fintech Breakthrough Awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards:  Fintech Breakthrough Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna  Fintech Breakthrough Awards" />
                        </div>
                        <h4 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Fintech Breakthrough Awards</strong></h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Fintech Breakthrough Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The Forttuna Global Excellence Fintech Breakthrough Awards honor the pioneers and innovators reshaping the financial landscape. By recognizing exceptional fintech solutions that drive financial inclusion, efficiency, and growth, the awards celebrate the transformative power of technology in the world of finance.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Recognition:</strong> Celebrate outstanding achievements in fintech innovation.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Evaluation:</strong> Benefit from a rigorous judging process by industry leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Diverse Impact:</strong> Showcase your fintech solution's impact across various sectors.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Networking Opportunities:</strong> Connect with other fintech pioneers and collaborate.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Spotlight:</strong> Gain international recognition for your fintech breakthrough.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Showcase Innovation:</strong> Gain global recognition for your fintech leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire and Connect:</strong> Network with industry leaders and motivate others.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Enhance Your Brand:</strong> Increase brand visibility and attract new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Media Exposure:</strong> Gain significant media attention for your fintech breakthrough.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Endorsement:</strong> Benefit from a fair evaluation by a distinguished panel.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}><strong>Fintech Breakthrough Awards</strong> <a href="https://forttuna.co/award-categories/">Categories</a> <strong>:</strong></h4>
                        <p><strong>Finance and Insurance-</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>Technology-</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <h5 style={{color: '#fff'}}><strong>Ready to shine a light?</strong></h5>
                        <h6 style={{color: '#fff'}}><strong>Nominate yourself or someone you know for recognition.</strong></h6>
                        <p>Shape the future of finance. Enter the Forttuna Global Excellence Fintech Breakthrough Awards.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p>Know About <a href="https://forttuna.co/awards/banking-tech-awards/">Banking Tech Awards</a>.</p>
                        <p><a href="https://forttuna.co/awards/real-estate-awards/">Learn More</a> About the Excellence Award.</p>
                        <p><a href="https://participate.forttuna.co/">Be a part</a> of Forttuna awards in India and celebrate marketing awards.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
