
import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD18/BlogD18';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'Wellness Awards 2024: Networking & Growth Opportunities';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="The Wellness Awards 2024 is a monumental event that unravels a spectrum of opportunities that extend well beyond the podium, not just a moment of glory!" />
            <meta property="og:title" content="Wellness Awards 2024: Networking & Growth Opportunities" />
            <meta property="og:description" content="The Wellness Awards 2024 is a monumental event that unravels a spectrum of opportunities that extend well beyond the podium, not just a moment of glory!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="wellness awards 2024" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='Wellness Awards 2024: Networking & Growth Opportunities' pagesub={''} pageTitle2={''}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default BlogDetails;
