import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/business-1.jpg'
import abimg2 from '../../images/resource/business-2.jpg'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one business-on" id='about'>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                <h2 className="sec-title_heading">Introduction</h2>
                            </div>
                            <div className="business-one_text">
                                <p>The Forttuna Global Excellence Awards Program, established in July 2023 in the thriving city of Dubai, takes pride in its mission to honor and celebrate  <a href="https://forttuna.co/award-categories/">exemplary business leaders</a> on a global scale. With the India Edition, we extend this prestigious global awards program to recognize and celebrate the outstanding achievements of individuals and organizations within India. Our focus is on those who have demonstrated exceptional leadership, innovation, and impactful contributions across various industries. The India Edition is dedicated to highlighting the  <a href="https://participate.forttuna.co/">remarkable accomplishments</a> of those who set new benchmarks, drive excellence, and inspire others through their commitment and dedication. We aim to shine a spotlight on these trailblazers who are shaping the future of India's business landscape and contributing to its growth and development.</p>
                            </div>

                            <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/schedule" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Event Schedule</span>
                                        <span className="text-two">Event Schedule</span>
                                    </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;