import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import pIcon1 from '../../images/background/Privacy-Policy.webp';

const PrivacyPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Privacy Policy';
    }, []);

    return (
        <>
        <Helmet>
            <meta name="description" content="Learn about our privacy policy on the Forttuna Global Excellence Awards website. Join us in celebrating excellence across industries." />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Privacy Policy" />
            <meta property="og:description" content="Learn about our privacy policy on the Forttuna Global Excellence Awards website. Join us in celebrating excellence across industries." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Privacy Policy</div>
                            <h2 className="page-title_heading">Privacy Policy</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Cookie Policy</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            {/* <PageTitle pageTitle={'Privacy Policy'} pagesub={'Privacy Policy'}  pageTitle2={'Privacy Policy'} /> */}
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content">
                        <h4>Last Updated: [25th January 2024]</h4>
                        <p>Thank you for choosing Forttuna Global Excellence Awards. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information. By interacting with our platform, you agree to the terms outlined in this policy.</p>
                        <h4>1. Information Collection:</h4>
                        <h5>1.1. Personal Information:</h5>
                        <p>We may collect personal information, including but not limited to names, contact details, job titles, and organisational affiliations, when you engage with our services, submit nominations, or participate in our events.</p>
                        <h5>1.2. Non-Personal Information:</h5>
                        <p>We may automatically collect non-personal information, such as browser type, IP address, and device information, to enhance your experience and improve our services.</p>
                        <h4>2. Use of Information:</h4>
                        <h5>2.1. Award Participation:</h5>
                        <p>Your personal information may be used for award nominations, participant communication, and event coordination.</p>
                        <h5>2.2. Communication:</h5>
                        <p>We may use your contact information to send relevant updates, newsletters, and promotional materials related to Forttuna Global Excellence Awards. You can opt-out of these communications at any time.</p>
                        <h5>2.3. Analytics and Improvements:</h5>
                        <p>Non-personal information may be used for analytics, research, and service enhancements to better understand user preferences and improve our offerings.</p>
                        <h4>3. Information Sharing:</h4>
                        <h5>3.1. Third-Party Service Providers:</h5>
                        <p>We may share personal and non-personal information with third-party service providers for purposes such as event management, analytics, and communication services.</p>
                        <h5>3.2. Legal Compliance:</h5>
                        <p>We may disclose information in response to legal requests, court orders, or to comply with applicable laws.</p>
                        <h4>4. Security:</h4>
                        <p>We employ industry-standard security measures to protect your information from unauthorized access, disclosure, or alteration. However, no online method is entirely secure, and we cannot guarantee absolute security.</p>
                        <h4>5. Cookies:</h4>
                        <p>We use cookies to enhance your browsing experience. You can manage your cookie preferences through your browser settings.</p>
                        <h4>6. Your Rights:</h4>
                        <p>You have the right to access, correct, or delete your personal information. To exercise these rights or inquire about our privacy practices, contact us at [contact@forttuna.co].</p>
                        <h4>7. Children's Privacy:</h4>
                        <p>Forttuna Global Excellence Awards does not knowingly collect information from individuals under the age of 18. If we become aware of such information, we will take immediate steps to delete it.</p>
                        <h4>8. Updates to Privacy Policy:</h4>
                        <p>We may update this Privacy Policy periodically. The date of the latest revision will be indicated at the top. We encourage you to review the policy regularly.</p>
                        <h4>9. Contact Us:</h4>
                        <p>For inquiries or concerns regarding this Privacy Policy, please contact us at [contact@forttuna.co].</p>
                        <p>Thank you for entrusting Forttuna Global Excellence Awards with your information. Your privacy is important to us, and we are committed to maintaining the confidentiality and security of your data.</p>
                    </div>

                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default PrivacyPage;
