import React from 'react'
import blogs from '../../api/blogs'
import { Link } from 'react-router-dom'
import bImg from '../../images/background/4.jpg'
import blogImg2 from "../../images/resource/Business-Insider.webp";
import blogImg1 from "../../images/resource/Forbes.webp";
import blogImg3 from "../../images/resource/Khaleej-TImes.webp";

const BlogSection2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="news-two"  id='blog'>
            <div className="auto-container">
                <div className="sec-title">
                    <div className="d-flex justify-content-between align-items-end flex-wrap">
                        <div className="title-box title-anim">
                            <h2 className="sec-title_heading">Media Coverage</h2>
                        </div>
                        <div className="button-box title-anim">
                            <Link to="/newsroom/" className="theme-btn btn-style-one">
                                <span className="btn-wrap">
                                    <span className="text-one">View More</span>
                                    <span className="text-two">View More</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    {/* {blogs.slice(0, 3).map((blog, Bitem) => ( */}
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/leadership-effectiveness-and-social-responsibility-cornerstones-of-excellence/92727/1#google_vignette'  target="_blank"><img src={blogImg1} alt="" /></a>
                                    <img src={blogImg1} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '235px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/leadership-effectiveness-and-social-responsibility-cornerstones-of-excellence/92727/1#google_vignette'  target="_blank">Leadership effectiveness and social responsibility: Cornerstones of excellence</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/leadership-effectiveness-and-social-responsibility-cornerstones-of-excellence/92727/1#google_vignette'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://markets.businessinsider.com/news/stocks/forttuna-global-excellence-awards-announces-business-leaders-edition-2024-in-dubai-1033234526'  target="_blank"><img src={blogImg2} alt="" /></a>
                                    <img src={blogImg2} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '235px'}}>
                                    <h4 className="news-block_one-title"><a href='https://markets.businessinsider.com/news/stocks/forttuna-global-excellence-awards-announces-business-leaders-edition-2024-in-dubai-1033234526'  target="_blank">Forttuna Global Excellence Awards Announces Business Leaders Edition 2024 in Dubai</a></h4>
                                    <a href='https://markets.businessinsider.com/news/stocks/forttuna-global-excellence-awards-announces-business-leaders-edition-2024-in-dubai-1033234526'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.khaleejtimes.com/kt-network/forttuna-global-excellence-awards-2023-a-resounding-success-celebrating-healthcare-pioneers?_refresh=true'  target="_blank"><img src={blogImg3} alt="" /></a>
                                    <img src={blogImg3} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '235px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.khaleejtimes.com/kt-network/forttuna-global-excellence-awards-2023-a-resounding-success-celebrating-healthcare-pioneers?_refresh=true'  target="_blank">Forttuna Global Excellence Awards 2023: A resounding success celebrating healthcare pioneers</a></h4>
                                    <a href='https://www.khaleejtimes.com/kt-network/forttuna-global-excellence-awards-2023-a-resounding-success-celebrating-healthcare-pioneers?_refresh=true'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        
                    {/* ))} */}

                </div>
            </div>
        </section>
    )
}

export default BlogSection2;