import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/award-min/Fintech-Awards.webp'
import eimg2 from '../../images/award-min/Aviation-Awards.webp'
import eimg3 from '../../images/award-min/The-Forttuna-Global-Excellence-Awards-Business-Awards.webp'
import eimg4 from '../../images/award-min/Corporate-and-Financial-Awards.webp'
import eimg5 from '../../images/award-min/Healthcare-IT-Leaders-Awards.webp'
import eimg6 from '../../images/award-min/Health-Insurance-Awards.webp'
import eimg7 from '../../images/award-min/Wellness-Awards-2024.webp'
import eimg8 from '../../images/award-min/The-Forttuna-Global-Excellence-Awards-Corporate-Awards.webp'
import eimg9 from '../../images/award-min/HR-Awards.webp'
import eimg10 from '../../images/award-min/Business-Awards.webp'
import eimg11 from '../../images/award-min/Marketing-Awards.webp'
import eimg12 from '../../images/award-min/Entrepreneur-Awards.webp'
import eimg13 from '../../images/award-min/Healthcare-Awards.webp'
import eimg14 from '../../images/award-min/Real-Estate-Awards.webp'
import eimg15 from '../../images/award-min/Awards-Education-Awards.webp'
import eimg16 from '../../images/award-min/Small-Business-Awards.webp'
import eimg17 from '../../images/award-min/Health-Services-Awards.webp'
import eimg18 from '../../images/award-min/The-Forttuna-Global-Excellence-Awards-Architecture-Awards.webp'
import eimg19 from '../../images/award-min/Manufacturing-and-Associated-Industries-and-Occupations-Award.webp'
import eimg20 from '../../images/award-min/Forttuna-Global-Excellence-Awards-Women-In-Business-Awards.webp'
import eimg21 from '../../images/award-min/Hospitality-Award.webp'
import eimg22 from '../../images/award-min/Health-Professionals-and-Support-Services-Award.webp'
import eimg23 from '../../images/award-min/Technology-Award.webp'
import eimg24 from '../../images/award-min/Health-Innovation-Awards.webp'
import eimg25 from '../../images/award-min/Health-ProfessionalsAwards.webp'
import eimg26 from '../../images/award-min/Real-Estate-Industry.webp'
import eimg27 from '../../images/award-min/SME-Business-Awards.webp'
import eimg28 from '../../images/award-min/Manufacturing-Awards.webp'
import eimg29 from '../../images/award-min/health-support.webp'
import eimg30 from '../../images/award-min/Business-Excellence.webp'
import eimg31 from '../../images/award-min/Fintech-Breakthrough-Awards.webp'
import eimg32 from '../../images/award-min/Health-Awards.webp'
import eimg33 from '../../images/award-min/Nurse-Awards.webp'
import eimg34 from '../../images/award-min/Banking-Tech-Awards.webp'
import eimg35 from '../../images/award-min/Healthcare-Awards.webp'

const awards = [
  { img: eimg1, link: "/fintech-award/", title: "The Forttuna Global Excellence Awards: Fintech Awards", text: "First-ever Jury Led Fintech Award: Be Recognized on the World Stage. Do you have a revolutionary fintech product or service that's transforming the financial..." },
  { img: eimg2, link: "/aviation-awards/", title: "The Forttuna Global Excellence Awards: Aviation Awards", text: "First-ever Jury Led Aviation Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Awards invites you to take flight in the prestigious Aviation..." },
  { img: eimg3, link: "/international-business-awards/", title: "The Forttuna Global Excellence Awards: International Business Awards", text: "First-ever Jury Led International Business Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Awards are ready to celebrate your success on the..." },
  { img: eimg4, link: "/corporate-and-financial-award/", title: "The Forttuna Global Excellence Awards: Corporate and Financial Awards", text: "First-ever Jury Led Corporate and Financial Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Awards are proud to celebrate your achievements in..." },
  { img: eimg5, link: "/healthcare-advertising-awards/", title: "The Forttuna Global Excellence Awards: Healthcare Advertising Awards", text: "The healthcare industry thrives on innovation, but progress hinges on clear communication. The Forttuna Global Excellence Awards proudly presents the Healthcare Advertising Awards, recognizing the creative minds who bridge the gap between cutting-edge medicine and public understanding..." },
  { img: eimg6, link: "/awards/health-insurance-awards/", title: "The Forttuna Global Excellence Awards: Health Insurance Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The healthcare landscape thrives on constant progress, but for many, accessing these..." },
  { img: eimg7, link: "/awards/wellness-awards-2024/", title: "The Forttuna Global Excellence Awards: Wellness Awards 2024", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. Well-being extends far beyond the realm of traditional medicine in today's world. From fitness gurus..." },
  { img: eimg8, link: "/awards/corporate-awards/", title: "The Forttuna Global Excellence Awards: Corporate Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Awards celebrates outstanding achievement in the realm of corporate awards..." },
  { img: eimg9, link: "/awards/hr-awards/", title: "The Forttuna Global Excellence Awards: HR Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Awards honors the exceptional individuals who are revolutionizing the HR landscape in its..." },
  { img: eimg10, link: "/awards/business-awards/", title: "The Forttuna Global Excellence Awards: Business Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The world of business thrives on innovation, impactful leadership, and a relentless pursuit of excellence. The Forttuna Global Excellence..." },
  { img: eimg11, link: "/awards/marketing-awards/", title: "The Forttuna Global Excellence Awards: Marketing Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The masterminds behind strategies capturing attention, igniting imaginations, and forging connections – the marketers – often remain unseen..." },
  { img: eimg12, link: "/awards/entrepreneur-awards/", title: "The Forttuna Global Excellence Awards: Entrepreneur Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The masterminds behind strategies capturing attention, igniting imaginations, and forging connections – the marketers – often remain unseen..." },
  { img: eimg13, link: "/awards/advertising-awards/", title: "The Forttuna Global Excellence Awards: Healthcare Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. Healthcare professionals are the frontline heroes who tirelessly work to improve lives, advance medical knowledge..." },
  { img: eimg14, link: "/awards/real-estate-awards/", title: "The Forttuna Global Excellence Awards: Real Estate Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The world we live, work, and thrive in is shaped by the vision and expertise of real estate professionals..." },
  { img: eimg15, link: "/awards/education-awards/", title: "The Forttuna Global Excellence Awards: Education Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. Educators play a truly transformative role in the enduring quest to nurture knowledge, empower potential, and shape the future...." },
  { img: eimg16, link: "/awards/small-business-awards/", title: "The Forttuna Global Excellence Awards: Small Business awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The backbone of any thriving economy lies in the unwavering spirit and innovation of small businesses. The Forttuna Global Excellence Awards takes..." },
  { img: eimg17, link: "/awards/health-services-awards/", title: "The Forttuna Global Excellence Awards: Health Services awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. Health professionals' unwavering dedication and exceptional skills are nothing short of heroic..." },
  { img: eimg18, link: "/awards/architecture-awards/", title: "The Forttuna Global Excellence Awards: Architecture Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The built environment around us – a symbol of human ingenuity and artistry – is shaped by the vision and creativity of architects..." },
  { img: eimg19, link: "/awards/manufacturing-awards/", title: "The Forttuna Global Excellence Awards: Manufacturing Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The manufacturing industry is the backbone of our world, shaping the physical landscape of human innovation..." },
  { img: eimg20, link: "/awards/women-in-business-awards/", title: "The Forttuna Global Excellence Awards: Women In Business Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The business world thrives on innovation, resilience, and leadership – qualities that women in business consistently demonstrate..." },
  { img: eimg21, link: "/awards/hospitality-awards/", title: "The Forttuna Global Excellence Awards: Hospitality Award", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The hospitality industry thrives on the dedication of its people. Their creativity, passion, and commitment to service leave guests feeling valued and truly welcome..." },
  { img: eimg22, link: "/awards/health-professionals/", title: "The Forttuna Global Excellence Awards: Health Professionals and Support Services Award", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The healthcare industry is a complex ecosystem where countless individuals work tirelessly to ensure our well-being. From doctors and nurses..." },
  { img: eimg23, link: "/awards/technology-award/", title: "The Forttuna Global Excellence Awards: Technology Award", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The ever-evolving landscape of technology is shaped by the brilliant minds who push the boundaries of what's possible. These are the pioneers..." },
  { img: eimg24, link: "/awards/health-innovation-awards/", title: "The Forttuna Global Excellence Awards: Health Innovation Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The healthcare industry is constantly evolving, pushed forward by the tireless efforts of health innovation professionals...." },
  { img: eimg25, link: "/awards/health-professionals-award/", title: "The Forttuna Global Excellence Awards: Health Professionals Award", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The healthcare industry stands tall on the shoulders of its dedicated professionals. From doctors diagnosing and treating illnesses to nurses..." },
  { img: eimg27, link: "/awards/sme-business-awards/", title: "The Forttuna Global Excellence Awards: SME Business Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. Small and medium-sized enterprises (SMEs) are the lifeblood of the global economy. These dynamic businesses are often the..." },
  { img: eimg28, link: "/awards/manufacturing-and-occupation/", title: "The Forttuna Global Excellence Awards: Manufacturing and Associated Industries and Occupations Award", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The engine of our global economy hums thanks not only to manufacturers themselves, but also to the vast network of professionals in associated..." },
  { img: eimg29, link: "/awards/health-support-services-award/", title: "The Forttuna Global Excellence Awards: Health Support Services Award", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Healthcare industry thrives on a delicate balance. While doctors diagnose and treat, a dedicated team of health support service professionals works tirelessly..." },
  { img: eimg30, link: "/awards/business-excellence-awards/", title: "The Forttuna Global Excellence Awards: Business Excellence Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Awards recognizes exceptional businesses that have redefined industry standards and driven positive change. By..." },
  { img: eimg31, link: "/awards/fintech-breakthrough-awards/", title: "The Forttuna Global Excellence Awards: Fintech Breakthrough Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Fintech Breakthrough Awards honor the pioneers and innovators reshaping the financial landscape. By recognizing..." },
  { img: eimg32, link: "/awards/health-awards/", title: "The Forttuna Global Excellence Awards: Health Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Health Awards recognize and celebrate the exceptional contributions of individuals and organizations transforming the..." },
  { img: eimg33, link: "/awards/nurse-awards/", title: "The Forttuna Global Excellence Awards: Nurse Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Nurse Awards honor the unsung heroes who dedicate their lives to caring for others. By recognizing exceptional nurses who demonstrate..." },
  { img: eimg34, link: "/awards/banking-tech-awards/", title: "The Forttuna Global Excellence Awards: Banking Tech Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage. The Forttuna Global Excellence Banking Tech Awards recognize and honor the groundbreaking innovations transforming the banking industry..." },
  { img: eimg35, link: "/awards/healthcare-awards/", title: "The Forttuna Global Excellence Awards: Healthcare Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage Healthcare professionals are the frontline heroes who tirelessly work to improve lives, advance medical knowledge, and ensure the well-being of countless individuals...." },
  { img: eimg35, link: "/awards/business-excellence-awards/", title: "The Forttuna Global Excellence Awards: Business Excellence Awards", text: "First-ever Jury Led** Business Excellence Awards: **Be Recognized on the World Stage** The Forttuna Global Excellence Awards recognizes exceptional businesses that have redefined...." },
  { img: eimg35, link: "/awards/healthcare-it-leaders-awards/", title: "The Forttuna Global Excellence Awards: Healthcare IT Leaders Awards", text: "First-ever Jury Led Awards: Be Recognized on the World Stage Healthcare professionals are the frontline heroes who tirelessly work to improve lives, advance medical knowledge, and ensure the well-being of countless individuals...." },
 
];

const LeadershipSection = ({ Awards, activeTab, toggle, ClickHandler }) => {
  const [visibleItems, setVisibleItems] = useState(8);

  const loadMore = () => {
    setVisibleItems(prevVisibleItems => Math.min(prevVisibleItems + 8, Awards.length));
  };

  return (
    <section className="leadership-one" id="events">
      <div className="auto-container">
        <div className="leadership-info-tabs">
          <div className="leadership-tabs tabs-box">
            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                {Awards.slice(0, visibleItems).map((event, i) => (
                  <div className="content" key={i}>
                    <div className="leadership-one_block" style={{ marginBottom: '30px' }}>
                      <div className="leadership-one_block-inner">
                        <div className="leadership-one_block-content">
                          <div className="leadership-one_block-image">
                            <img src={event.img} alt="" />
                            <div className="leadership-one_block-icon">
                              <i className={`fi flaticon-voice`}></i>
                            </div>
                          </div>
                          <div className="leadership-one_block-name">The Forttuna Group</div>
                          <h3 className="leadership-one_block-title">
                            <Link onClick={ClickHandler} to={event.link}>
                              {event.title}
                            </Link>
                          </h3>
                          <div className="leadership-one_block-text">{event.text}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {visibleItems < Awards.length && (
                  <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <button onClick={loadMore} className="theme-btn btn-style-onee">Load More</button>
                  </div>
                )}
              </TabPane>
              {/* Add other TabPanes here as needed */}
            </TabContent>
          </div>
        </div>
      </div>
    </section>
  );
};

const EventsSection = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <LeadershipSection Awards={awards} activeTab={activeTab} toggle={toggle} ClickHandler={ClickHandler} />
    </div>
  );
};

export default EventsSection;
