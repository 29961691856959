import timg from '../images/resource/manjulangani.webp'
import timg0 from '../images/resource/Sammer-Ellam.webp'
import timg1 from '../images/resource/Dr.-Jamil-Ahmed.webp'
import timg2 from '../images/resource/Dr.-Husnia-Gargash.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Dr.-Minal-Patwardhan-Andrade.webp'
import timg5 from '../images/resource/Dr.-Himanshu-Mehta.webp'

import tsimg from '../images/resource/manjulangani.webp'
import tsimg0 from '../images/resource/Sammer-Ellam.webp'
import tsimg1 from '../images/resource/Dr.-Jamil-Ahmed.webp'
import tsimg2 from '../images/resource/Dr.-Husnia-Gargash.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Dr.-Minal-Patwardhan-Andrade.webp'
import tsimg5 from '../images/resource/Dr.-Himanshu-Mehta.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'Padmashri Dr. Manjula Anagani',
      title: "Clinical Director, Women and Child Institute, Care Hospital, India",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Dr. Samer Ellaham',
      slug: 'Dr. Samer Ellaham',
      title: "Clinical Director Cleveland Clinic (UAE)   ",
   },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Jamil Ahmed',
      slug: 'Dr. Jamil Ahmed',
      title: "Founder & Managing Director Prime Healthcare Group (UAE)",
   },
   {
      Id: '4',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Dr. Husnia Gargash',
      slug: 'Dr. Husnia Gargash',
      title: 'Consultant Obstetrics & Gynecology And Reproductive Medicine, Founder, Gargash Hospital (UAE)',
   },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'Dr. Bimal Chhajer',
      title: 'Cardiologist, Founder, Saaol Heart Center (India)',
   },
   {
      Id: '6',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Minal Patwardhan Andrade',
      slug: 'Dr. Minal Patwardhan Andrade',
      title: 'Dermatologist, Founder, Minal Medical Center (UAE)',
   },
   {
      Id: '7',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Himanshu Mehta',
      slug: 'Dr. Himanshu Mehta',
      title: 'Ophthalmologist - The Vission Eye Center (India)',
   },
]

export default Teams;