import timg from '../images/resource/aditi-handa.webp'
import timg0 from '../images/resource/raul-handa.webp'

import tsimg from '../images/resource/speaker-1.jpg'
import tsimg0 from '../images/resource/speaker-1.jpg'


const Teams = [
   // {
   //    Id: '1',
   //    tImg: timg,
   //    tsImg: tsimg,
   //    name: 'Aditii Handa',
   //    slug: 'Aditii Handa',
   //    title: "Chairman and Managing Director, The Forttuna Group",
   // },
   // {
   //    Id: '2',
   //    tImg: timg0,
   //    tsImg: tsimg0,
   //    name: 'Raul Handa',
   //    slug: 'Raul Handa',
   //    title: "Founder & CEO, The Forttuna Group",
   // },

   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Aditii Handa',
      slug: 'aditii-handa',
      title: "Chairman and Managing Director, The Forttuna Group",
      link: "aditii-handa",
      link2: "https://www.linkedin.com/in/aditii-handa/",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Raul Handa',
      slug: 'raul-handa',
      title: "Founder & CEO, The Forttuna Group",
      link: "raul-handa",
      link2: "https://www.linkedin.com/in/raulahanda/",
   },

   // {
   //    Id: '8',
   //    tImg: timg8,
   //    tsImg: tsimg8,
   //    name: 'Harnold Min',
   //    slug: 'Harnold-Min',
   //    title: 'Starbucks CEO',
   // },

]

export default Teams;