import React from 'react'
import bg from '../../images/background/pattern-6.png'
import bg2 from '../../images/background/pattern-10.png'
import { Link } from 'react-router-dom'

const WhyAttendS2 = (props) => {

    const Service = [
        {
            title: 'Recognition of Leadership Excellence',
            des: 'Forttuna Global Excellence Awards is dedicated to acknowledging and celebrating the exemplary leadership demonstrated by business leaders across the globe.',
            icon: 'flaticon-connection'
        },
        {
            title: 'Global Reach and Impact',
            des: 'The award attracts participation from diverse industries, emphasising the impact of leaders who have made a difference not only within their local markets but on a broader, international stage.',
            icon: 'flaticon-book'
        },
        {
            title: 'Comprehensive Award Categories',
            des: 'Forttuna Global Excellence Awards categories encompass strategic leadership, innovation, sustainable practices, corporate social responsibility, and other key facets that define impactful business leadership.',
            icon: 'flaticon-idea'
        },
        {
            title: 'Exclusive Networking Opportunities',
            des: 'Winners of Forttuna Global Excellence Awards gain exclusive access to high-profile networking opportunities.',
            icon: 'flaticon-book'
        },
        {
            title: 'Emphasis on Innovation and Adaptability',
            des: 'Forttuna Global Excellence Awards appreciates leaders who have successfully navigated challenges, embraced technological advancements, and fostered a culture of innovation within their organisations.',
            icon: 'flaticon-book'
        },
        {
            title: 'Transparency and Impartiality',
            des: 'The awards are bestowed based on merit, ensuring that the recognition is fair and reflects the genuine impact of the leaders\' contributions.',
            icon: 'flaticon-book'
        },
        {
            title: 'Prestigious Award Ceremony in Dubai',
            des: 'The Forttuna Global Excellence Awards is a grand and exclusive award ceremony held in Dubai, a global business hub.',
            icon: 'flaticon-book'
        },
        {
            title: 'Positive Societal Impact Recognition',
            des: 'The awards program goes beyond financial metrics & includes leaders who have implemented sustainable practices, supported community initiatives, & demonstrated a commitment to corporate social responsibility.',
            icon: 'flaticon-book'
        },
        {
            title: 'Continued Excellence and Future-Focused Approach',
            des: 'Business leaders recognized by the program are those who have not only achieved success but have also demonstrated a commitment to evolving with the times, staying future-focused, and driving continuous improvement within their organisations.',
            icon: 'flaticon-book'
        },
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="services-one" >
            {/* <div className="services-one_pattern-one" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="services-one_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title centered title-anim" style={{textAlign: 'center'}}>
                    {/* <div className="sec-title_title">Join The Event</div> */}
                    <h2 className="sec-title_heading">Why Attend Event?</h2>
                </div>
                <div className="row clearfix">
                    {Service.map((service, sitem) => (
                        <div className="service-block_one col-lg-4 col-md-6 col-sm-12" key={sitem}>
                            <div className="service-block_one-inner" style={{height: '465px'}}>
                                <div className={`service-block_one-icon fi ${service.icon}`}></div>
                                <h4 className="service-block_one-heading"><Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop">{service.title}</Link></h4>
                                <div className="service-block_one-text">{service.des}</div>
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="service-block_one-arrow fi flaticon-next-2"></Link>
                            </div>
                        </div>
                    ))}
                    <p className='para'>The Forttuna Global Excellence Awards Program recognizes outstanding contributions on a global scale in <Link to='/awards/architecture-awards/'>Architecture</Link>, Construction and Engineering, Automobile, Aviation, Finance and Insurance, Fashion and Beauty, Entertainment and Media, Education, <Link to='/awards/hospitality-awards/'>Hospitality</Link>, <Link to='/awards/manufacturing-awards/'>Manufacturing</Link>, Marketing, Market Research, <Link to='/awards/hr-awards/'>Human Resources</Link>, <Link to='/awards/real-estate-industry-award/'>Real Estate</Link>, SMEs, and <Link to='/awards/technology-award/'>Technology</Link>.<br/> <br/> The Forttuna Global Excellence Awards - Business Edition celebrates exceptional business leadership driving success across <Link to='/award-categories/'>categories</Link>, either through a growing business award or an international business award.
                    </p>
                </div>
            </div>
        </section>

    )
}

export default WhyAttendS2;