import React from 'react'
import { Link } from 'react-router-dom'
import bg from '../../images/main-slider/image-2.webp'
import sIcon from '../../images/background/pattern-7.png'
import sIcon2 from '../../images/background/pattern-8.png'
import sIcon3 from '../../images/background/pattern-9.png'

const Features = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const featres = [
        {
            fIcon: 'flaticon-policy',
            title: ' Dedicated Seating for Awardees and Accompanying Guests',
        },
        {
            fIcon: 'flaticon-digital-learning',
            title: 'The FORTTUNA Global Excellence Awards will be Telecasted on Local and International TV Channels',
        },
        {
            fIcon: 'flaticon-conversation-1',
            title: 'Our Dignitaries will present the FORTTUNA Global Excellence Awards Trophy to all Winners',
        },
        {
            fIcon: 'flaticon-inspiration',
            title: 'Exclusive Photography and Videography coverage throughout the awards',
        },
        {
            fIcon: 'flaticon-inspiration',
            title: 'Networking Opportunities with Global Industry Leaders',
        },
        {
            fIcon: 'flaticon-inspiration',
            title: 'Media / Press Coverage by Local and International Media Outlets of the Event',
        },

    ]


    return (
        <section className="feature-one" style={{ backgroundImage: `url(${bg})` }}>
            {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
            <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
            <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title title-anim" style={{textAlign: 'center'}}>
                    {/* <div className="sec-title_title">Our Features</div> */}
                    <h2 className="sec-title_heading">Key Highlights</h2>
                </div>
                <div className="row clearfix">
                    {featres.map((featres, fitem) => (
                        <div className="feature-block_one col-lg-4 col-md-6 col-sm-12" key={fitem}>
                            <div className="feature-block_one-inner">
                                <div className="feature-block_one-icon"><i className={`fi ${featres.fIcon}`}></i></div>
                                <h4 className="feature-block_one-title fea" style={{color: '#c88c19'}}><Link onClick={ClickHandler} to="">{featres.title}</Link></h4>
                                {/* <Link onClick={ClickHandler} className="feature-block_one-more" to="/event">Read More</Link> */}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Features;