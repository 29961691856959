import timg from '../images/resource/shallen-verma.webp'
import timg0 from '../images/resource/sylvia-paulig.webp'
import timg1 from '../images/resource/bimal-chajjar.webp'
import timg2 from '../images/resource/manjulangani.webp'
import timg3 from '../images/resource/jimmy-mistry.webp'
import timg4 from '../images/resource/micheal-conner.webp'
import timg5 from '../images/resource/moran-cerf.jpg'
import timg6 from '../images/resource/kaiser-raja.webp'
import timg7 from '../images/resource/jay-manglani.webp'
import timg8 from '../images/resource/amit-sheth.webp'
import timg9 from '../images/resource/pratit-samdani.webp'
import timg10 from '../images/resource/speaker-1.jpg'
import timg11 from '../images/resource/speaker-1.jpg'
import timg12 from '../images/resource/speaker-1.jpg'

import tsimg from '../images/resource/shallen-verma.webp'
import tsimg0 from '../images/resource/sylvia-paulig.webp'
import tsimg1 from '../images/resource/bimal-chajjar.webp'
import tsimg2 from '../images/resource/manjulangani.webp'
import tsimg3 from '../images/resource/jimmy-mistry.webp'
import tsimg4 from '../images/resource/micheal-conner.webp'
import tsimg5 from '../images/resource/moran-cerf.jpg'
import tsimg6 from '../images/resource/kaiser-raja.webp'
import tsimg7 from '../images/resource/jay-manglani.webp'
import tsimg8 from '../images/resource/amit-sheth.webp'
import tsimg9 from '../images/resource/pratit-samdani.webp'
import tsimg10 from '../images/resource/speaker-1.jpg'
import tsimg11 from '../images/resource/speaker-1.jpg'
import tsimg12 from '../images/resource/speaker-1.jpg'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Dr. Shallen Verma',
      slug: 'Dr. Shallen Verma',
      title: "Specialist Periodontics, UAE",
   },
   // {
   //    Id: '2',
   //    tImg: timg0,
   //    tsImg: tsimg0,
   //    name: 'Dr. Sylvia Paulig',
   //    slug: 'Dr. Sylvia Paulig',
   //    title: "Ophthalmic Surgeon, Germany",
   // },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Bimal Chhajer',
      slug: 'Dr. Bimal Chhajer',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '4',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'Padmashri Dr. Manjula Anagani',
      title: 'Clinical Director, Women and Child Institute, Care Hospital, India',
   },
   // {
   //    Id: '5',
   //    tImg: timg3,
   //    tsImg: tsimg3,
   //    name: 'Jimmy Mistry',
   //    slug: 'Jimmy Mistry',
   //    title: 'CMD, Della Group, Singapore',
   // },
   {
      Id: '6',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Michael Conner',
      slug: 'Dr. Michael Conner',
      title: 'CEO/Founder @ Agile Evolutionary Group, USA',
   },
   {
      Id: '7',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Moran Cerf',
      slug: 'Dr. Moran Cerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '8',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Dr. Kaiser Raja',
      slug: 'Dr. Kaiser Raja',
      title: 'Director, Institute of Gastroenterology and Hepatology, Chief Transplant Hepatologist, King\'s College Hospital London, Dubai, UAE',
   },
   // {
   //    Id: '9',
   //    tImg: timg7,
   //    tsImg: tsimg7,
   //    name: 'Jay Magdani',
   //    slug: 'Jay Magdani',
   //    title: 'Chief Product Officer - Collective Artists Network, India',
   // },
   {
      Id: '10',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Amit Sheth',
      slug: 'Amit Sheth',
      title: 'Award-winning Serial Entrepreneur & Philanthropist, India',
   },
   // {
   //    Id: '11',
   //    tImg: timg9,
   //    tsImg: tsimg9,
   //    name: 'Dr. Pratit Samdani',
   //    slug: 'Dr. Pratit Samdani',
   //    title: 'Oncologist, Physician & Intensivist, India',
   // },
]

export default Teams;