// images
import blogImg1 from "../images/news/newspaper1.jpeg";
import blogImg2 from "../images/news/newspaper2.jpg";
import blogImg3 from "../images/news/newspaper3.jpg";
// import blogImg4 from "../images/resource/news-1.jpg";
// import blogImg5 from "../images/resource/news-2.jpg";
// import blogImg6 from "../images/resource/news-3.jpg";

import blogSingleImg1 from "../images/news/newspaper1.jpeg";
import blogSingleImg2 from "../images/news/newspaper2.jpg";
import blogSingleImg3 from "../images/news/newspaper3.jpg";
// import blogSingleImg4 from "../images/resource/news-13.jpg";
// import blogSingleImg5 from "../images/resource/news-14.jpg";
// import blogSingleImg6 from "../images/resource/news-15.jpg";

import Author1 from "../images/resource/author-1.jpg";
import Author2 from "../images/resource/author-2.jpg";



const blogs = [
    {
        id: '1',
        title: 'Leadership effectiveness and social responsibility: Cornerstones of excellence',
        slug:'leadership-effectiveness-and-social-responsibility:-cornerstones-of-excellence',
        screens: blogImg3,
        link :'https://www.forbesindia.com/article/brand-connect/leadership-effectiveness-and-social-responsibility-cornerstones-of-excellence/92727/1',
        // description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        // author: 'Villalpando',
        // authorTitle:'Criminal Lawyer',
        // create_at: '14 AUG,23',
        // blogSingleImg:blogSingleImg1, 
        // comment:'35',
        // thumb:'Event',
        // blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Forttuna Global Excellence Awards Announces Business Leaders Edition 2024 in Dubai',
        slug:'forttuna-global-excellence-awards-announces-business-leaders-edition-2024-in-dubai',
        screens: blogImg1,
        link :'https://markets.businessinsider.com/news/stocks/forttuna-global-excellence-awards-announces-business-leaders-edition-2024-in-dubai-1033234526',
        // description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        // author: 'Konal Biry',
        // authorTitle:'Family Lawyer',
        // create_at: '16 AUG,23',
        // blogSingleImg:blogSingleImg2, 
        // comment:'80',
        // thumb:'Conference',
        // blClass:'format-video',
    },
    {
        id: '3',
        title: 'Forttuna Global Excellence Awards 2023: A resounding success celebrating healthcare pioneers',
        slug:'forttuna-global-excellence-awards-2023:-a-resounding-success-celebrating-healthcare-pioneers',
        screens: blogImg2,
        link :'https://www.khaleejtimes.com/kt-network/forttuna-global-excellence-awards-2023-a-resounding-success-celebrating-healthcare-pioneers?_refresh=true',
        // description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        // author: 'Jenefer Willy',
        // authorTitle:'Business Lawyer',
        // create_at: '18 AUG,23',
        // blogSingleImg:blogSingleImg3,
        // comment:'95',
        // thumb:'Event',
        // blClass:'format-standard-image',
    },
    // {
    //     id: '4',
    //     title: 'International Conference On Art Business Speck',
    //     slug:'International-Conference-On-Art-Business-Speck',
    //     screens: blogImg4,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Villalpando',
    //     authorTitle:'Criminal Lawyer',
    //     create_at: '14 AUG,23',
    //     blogSingleImg:blogSingleImg4, 
    //     comment:'35',
    //     thumb:'Event',
    //     blClass:'format-standard-image',
    //     AuthorImg:Author1,
    // },
    // {
    //     id: '5',
    //     title: 'Unique Digital Ideas To Learn Business Growth',
    //     slug:'Unique-Digital-Ideas-To-Learn-Business-Growth',
    //     screens: blogImg5,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Konal Biry',
    //     authorTitle:'Family Lawyer',
    //     create_at: '16 AUG,23',
    //     blogSingleImg:blogSingleImg5, 
    //     comment:'80',
    //     thumb:'Conference',
    //     blClass:'format-video',
    //     AuthorImg:Author2,
    // },
    // {
    //     id: '6',
    //     title: 'Houston Even Management For Us',
    //     slug:'Houston-Event-Planning-Management-For-Us',
    //     screens: blogImg6,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'Business Lawyer',
    //     create_at: '18 AUG,23',
    //     blogSingleImg:blogSingleImg6,
    //     comment:'95',
    //     thumb:'Event',
    //     blClass:'format-standard-image',
    //     AuthorImg:Author1,
    // },
    // {
    //     id: '7',
    //     title: 'Planning Management For Us',
    //     slug:'Houston-Event-Planning-Management-For-Us',
    //     screens: blogImg6,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'Business Lawyer',
    //     create_at: '18 AUG,23',
    //     blogSingleImg:blogSingleImg6,
    //     comment:'95',
    //     thumb:'Event',
    //     blClass:'format-standard-image',
    //     AuthorImg:Author1,
    // },
    // {
    //     id: '8',
    //     title: 'Houston Event Management For Us',
    //     slug:'Houston-Event-Planning-Management-For-Us',
    //     screens: blogImg6,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'Business Lawyer',
    //     create_at: '18 AUG,23',
    //     blogSingleImg:blogSingleImg6,
    //     comment:'95',
    //     thumb:'Event',
    //     blClass:'format-standard-image',
    //     AuthorImg:Author1,
    // },
    // {
    //     id: '9',
    //     title: 'Houston Event Planning Managet For Us',
    //     slug:'Houston-Event-Planning-Management-For-Us',
    //     screens: blogImg6,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'Business Lawyer',
    //     create_at: '18 AUG,23',
    //     blogSingleImg:blogSingleImg6,
    //     comment:'95',
    //     thumb:'Event',
    //     blClass:'format-standard-image',
    //     AuthorImg:Author1,
    // },
    // {
    //     id: '10',
    //     title: 'Houston Event Planning Us',
    //     slug:'Houston-Event-Planning-Management-For-Us',
    //     screens: blogImg6,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'Business Lawyer',
    //     create_at: '18 AUG,23',
    //     blogSingleImg:blogSingleImg6,
    //     comment:'95',
    //     thumb:'Event',
    //     blClass:'format-standard-image',
    //     AuthorImg:Author1,
    // },
];

export default blogs;