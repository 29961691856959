import React from 'react'
import { Link } from 'react-router-dom'
import OurBoard from '../../api/OurBoard'
import shape1 from '../../images/background/pattern-4.png'
import shape2 from '../../images/background/pattern-6.png'
import shape3 from '../../images/background/pattern-5.png'

const Speakers = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one speakers-onn" id="speakers">
            {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Boards</div> */}
                    <h2 className="sec-title_heading">Board Member</h2>
                </div>
                <div className="row clearfix" style={{ justifyContent: 'center' }}>
                    {OurBoard.slice(0, 20).map((team, aitem) => (
                        <div className="speaker-block_one col-lg-3 col-md-6 col-sm-12" key={aitem}>
                            <div className="speaker-block_one-inner">
                                <div className="speaker-block_one-image">
                                    <img src={team.tImg} alt="" />
                                    <div className="speaker-block_one-pattern"></div>
                                    {/* <div className="speaker-block_one-social">
                                        <span className="speaker-block_one-share fa fa-share-alt"></span>
                                        <div className="speaker-block_one-social-list">
                                            <Link onClick={ClickHandler} to={`/`} className="facebook fa fa-linkedin"></Link>
                                           </div>
                                    </div> */}
                                </div>
                                <div className="speaker-block_one-content">
                                    <h4 className="speaker-one_title" style={{color: '#fff'}}>{team.name}</h4>
                                    <div className="speaker-one_detail">{team.title}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Speakers;
