import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const formData = new FormData();
            formData.append('name', forms.name);
            formData.append('email', forms.email);
            formData.append('subject', forms.subject);
            formData.append('phone', forms.phone);
            formData.append('message', forms.message);

            fetch('sendEmail.php', {
                method: 'POST',
                body: formData
            })
            .then(response => response.text())
            .then(data => {
                alert(data);
                setForms({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: ''
                });
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Failed to send your message. Please try again later.');
            });

            validator.hideMessages();
        } else {
            validator.showMessages();
            alert('Please fill all fields correctly.');
        }
    };

    return (
        <form action='https://forttuna.co/mail/' method='POST' className='contactForm'>
            <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Name" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="text"
                            name="phone"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Phone" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.subject}
                            type="text"
                            name="subject"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Subject" />
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <textarea
                        onBlur={changeHandler}
                        onChange={changeHandler}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <div className="button-box">
                        <button className="btn-style-one theme-btn" type="submit">
                            <div className="btn-wrap">
                                <span className="text-one">submit now</span>
                                <span className="text-two">submit now</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
