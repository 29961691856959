import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Scrollbar = () => {

    return (

        <AnchorLink href='#scrool'>
            <div className="progress-wrap">
                
            </div>
        </AnchorLink>

    )
}

export default Scrollbar;
