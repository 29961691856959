import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogD7/BlogD7';
import blogs from '../../api/blogs'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';


const BlogDetails = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'Unveiling the Modern Healthcare Awards: Why Winning It Matters';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Unveiling the Modern healthcare awards do more than recognize; they also amplify the work of the other recipients." />
            <meta property="og:title" content="Beyond the Stethoscope: Honoring Women Leaders in Healthcare" />
            <meta property="og:description" content="Unveiling the Modern healthcare awards do more than recognize; they also amplify the work of the other recipients." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle='Unveiling the Modern Healthcare Awards: Why Winning It Matters' pagesub={''} pageTitle2={''}/>
            <BlogSingle />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default BlogDetails;
