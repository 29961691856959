import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Teams from '../../api/Teams'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import bg from '../../images/background/pattern-27.webp'
import bg2 from '../../images/background/faq.jpg'
import tImg from '../../images/resource/raul-handa.webp'
import fobos from '../../images/resource/Forbes-4.webp'


const SpeakerSinglePage = (props) => {
    const { slug } = useParams()

    const TeamDetails = Teams.find(item => item.slug === slug)


    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Raul Handa';
    }, []);


    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Global Excellence Awards | Raul Handa" />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Raul Handa" />
            <meta property="og:description" content="Forttuna Global Excellence Awards | Raul Handa" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <PageTitle pageTitle= 'Raul Handa' pagesub='Raul Handa' pageTitle2={'Raul Handa'} />
            <section className="team-single-section">
                <div className="speaker-detail">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="speaker-detail_author-column col-lg-4 col-md-6 col-sm-12">
                                <div className="speaker-detail_author-inner">
                                    {/* <div className="speaker-detail_author-pattern" style={{ backgroundImage: `url(${bg})` }}></div> */}
                                    <div className="speaker-detail_author-image">
                                        <img src={tImg} alt="" />
                                        <div className="speaker-detail_social-list">
                                            {/* <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="facebook fa fa-facebook"></Link>
                                            <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="youtube fi flaticon-youtube"></Link>
                                            <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="instagram fa fa-instagram"></Link> */}
                                            <a target='_blank' href="https://www.linkedin.com/in/raulahanda/" className="twitter fa fa-linkedin"></a>
                                        </div>
                                    </div>
                                    {/* <div className="speaker-detail_author-content">
                                        <h4 className="speaker-detail_title">Raul Handa</h4>
                                        <div className="speaker-detail_designation">Raul Handa</div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                <h2 className="speaker-detail_subtitle">Raul Handa</h2>
                                <h5><strong style={{color: '#fff'}}>Founder & CEO, The Forttuna Group</strong></h5>
                                <p style={{color: '#fff'}}>Raul Handa, a visionary entrepreneur, serves as the Founder and CEO of The Forttuna Group, leading the charge in recognizing brilliance across industries. With a portfolio spanning diverse ventures, he embodies innovation and leadership. Renowned as a dynamic speaker and award-winning leader, Raul excels in Operations, Sales, Customer Experience, and Scaling Businesses. As the esteemed host of the FFTB show, he continues to inspire and empower audiences worldwide with his insights and expertise beyond the excellence awards.</p>
                               <div>
                                    <img src={fobos}/>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default SpeakerSinglePage;
