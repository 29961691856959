import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Manufacturing-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)
    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards : Manufacturing Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="A prestigious jury-led and merit-based manufacturing awards program recognizing exceptional achievements across various industries." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards : Manufacturing Awards" />
            <meta property="og:description" content="A prestigious jury-led and merit-based manufacturing awards program recognizing exceptional achievements across various industries." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="manufacturing awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Manufacturing Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna manufacturing awards" />
                        </div>
                        <h4 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Manufacturing Awards</h4>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Manufacturing Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The manufacturing industry is the backbone of our world, shaping the physical landscape of human innovation.</p>
                        <p>The Forttuna Global Excellence Awards - Manufacturing Awards are here to recognize the exceptional contributions of manufacturers who are setting a <a href="https://www.investopedia.com/terms/b/benchmark.asp">benchmark</a> for excellence that motivates future leaders in manufacturing. Join us in celebrating their achievements on the global stage.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Awards?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>A prestigious jury-led and merit-based manufacturing awards program recognizing exceptional achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Award of Excellence to honor individuals and companies through manufacturing awards making a significant impact on the global landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>More than just global awards - a chance to connect and collaborate with like-minded individuals across diverse fields.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain global recognition for your company's innovation and leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase individual talent and inspire others through achievement.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with industry leaders at our prestigious manufacturing awards ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Elevate your brand profile and attract new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain significant media exposure, propelling your achievements into the spotlight.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fair evaluation by a distinguished panel of international jury experts.</li>
                        </ul>
                        <h4 style={{color: '#fff'}}>Manufacturing Awards <a href="https://forttuna.co/award-categories/">Categories</a> :</h4>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Angel Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <h5 style={{color: '#fff'}}>Ready to shine a light?</h5>
                        <h6 style={{color: '#fff'}}>Participate yourself or someone you know for recognition.</h6>
                        <p>Let's celebrate your achievements together at this Manufacturing Awards!</p>
                        <p><a href="https://participate.forttuna.co/">Nominate</a> to be a part of Forttuna India.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now or Start Your Participation</a></p>
                        <p><a href="https://forttuna.co/awards/architecture-awards/">Learn More</a> About the Excellence Award.</p>
                        <p>Also Know About <a href="https://forttuna.co/awards/manufacturing-and-occupation/">Manufacturing and Associated Industries and Occupations Award.</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
