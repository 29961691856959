import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/Aim-1.webp'
import abimg2 from '../../images/resource/Aim-2.webp'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one" id='about'>
            <div className="auto-container" style={{marginTop: '120px'}}>
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                {/* <div className="sec-title_title">Become Succesfuly day</div> */}
                                <h2 className="sec-title_heading">Our Vision</h2>
                            </div>
                            <div className="business-one_text"><p>The Forttuna Global Excellence Awards Program aims to attract a diverse and accomplished demographic that spans <a href="https://forttuna.co/award-categories/">various industries and sectors</a>. Our target audience includes individuals, organizations, and businesses that have demonstrated exceptional performance and innovation in their respective fields. This prestigious award seeks to celebrate and recognize achievements on a global scale, embracing a wide range of talents and contributions.</p><p>Our goal is to honor those who have set benchmarks of excellence in their areas of expertise. These may include but are not limited to, business leaders, healthcare professionals, innovators in technology, pioneers in sustainable practices, and trailblazers in arts and culture. By recognizing such varied accomplishments, the Forttuna Global Excellence Awards highlights the importance of diverse perspectives and interdisciplinary success.</p></div>
                            {/* <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Book Ticket</span>
                                        <span className="text-two">Book Ticket</span>
                                    </span>
                                </Link>
                            </div> */}

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            {/* <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div> */}
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;